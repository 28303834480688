














import { defineComponent, computed } from '@vue/composition-api';
import AppTariffUpgradeItem from '@/components/shared/tariffUpgrade/TariffUpgradeItem.vue';
import { useMasterDataStore } from '@/stores/master-data';
import { ProjectType } from '@/model/store';

export default defineComponent({
  name: 'AppTariffUpgrade',
  components: { AppTariffUpgradeItem },
  props: {
    upgradeActionLabel: String,
  },
  emits: ['upgrade-tariff'],
  setup() {
    const masterDataStore = useMasterDataStore();

    const projectTypesResult = computed(() => {
      return masterDataStore.projectTypes.filter(({ name }) => name !== ProjectType.s);
    });

    return {
      projectTypesResult,
    };
  },
});
