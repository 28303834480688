import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { Location, Route } from 'vue-router';

export function formatDate(date: any): string {
  if (!_.isNil(date)) {
    return new Intl.DateTimeFormat(navigator.language, {
      dateStyle: 'short',
      timeStyle: 'short',
    }).format(new Date(date));
  }
  return '';
}

export function prettyBytes(bytes: number | undefined, precision = 1, addSpace = true): string {
  if (_.isNil(bytes)) {
    bytes = 0;
  }
  const UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  if (Math.abs(bytes) < 1) return bytes + (addSpace ? ' ' : '') + UNITS[0];
  const exponent = Math.min(Math.floor(Math.log10(bytes < 0 ? -bytes : bytes) / 3), UNITS.length - 1);
  const n = Number(((bytes < 0 ? -bytes : bytes) / 1000 ** exponent).toPrecision(precision));
  return (bytes < 0 ? '-' : '') + n + (addSpace ? ' ' : '') + UNITS[exponent];
}

export function urlWithoutProtocol(url: string): string {
  if (url.includes('://')) {
    return url.substring(url.indexOf('://') + 3);
  }
  return url;
}

export function urlWithHttpsProtocol(url: string): string {
  if (!url.includes('://')) {
    return 'https://' + url;
  }
  return url;
}

export function mapBrowserLanguageToMarket(): string {
  const browserLanguage = navigator.language.toUpperCase();
  if (_.startsWith(browserLanguage, 'DE')) {
    return 'DE';
  }
  if (_.endsWith(browserLanguage, 'GB')) {
    return 'GB';
  }
  return 'US';
}

const STORAGE_KEY_INITIAL_LOCATION = 'ionosspaceInitialLocation';
export function rememberActualLocation(route?: Route): void {
  const actualLocation = {
    path: '',
    query: {},
  };
  if (route) {
    actualLocation.path = route.path;
    actualLocation.query = route.query;
  } else {
    actualLocation.path = window.location.pathname;
    actualLocation.query = Object.fromEntries(new URLSearchParams(window.location.search));
  }
  sessionStorage.setItem(STORAGE_KEY_INITIAL_LOCATION, JSON.stringify(actualLocation));
}

export function popPersistedActualLocation(): Location | null {
  const persistedActualLocation = sessionStorage.getItem(STORAGE_KEY_INITIAL_LOCATION);
  if (persistedActualLocation) {
    sessionStorage.removeItem(STORAGE_KEY_INITIAL_LOCATION);
    return JSON.parse(persistedActualLocation);
  }
  return null;
}

export function sleep(ms: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(() => resolve(), ms);
  });
}

export function cloneDeepWithUUID<T extends { id: string }>(obj: T): T {
  const clone = _.cloneDeep(obj);
  clone.id = uuid();
  return clone;
}

export function spliceOrPush<T>(arr: T[], item: T, find: (item: T, index: number, obj: T[]) => boolean) {
  const index = arr.findIndex(find);
  if (arr.length && index > -1) {
    arr.splice(index, 1, item);
  } else {
    arr.push(item);
  }
}

// Hacky way to call an object method without TS compiler complaining
export function runSafe<T = any>(obj: T, fn: string, ...args: any[]) {
  if (obj && typeof obj === 'object' && typeof obj[fn] === 'function') {
    //@ts-ignore
    obj[fn](...args);
  }
}

export function removeByUuid<T extends { uuid: string }>(items: T[], uuid: string): T[] {
  const index = items.findIndex((item) => item.uuid === uuid);
  if (index !== -1) {
    items.splice(index, 1);
  }
  return items;
}

export function getOwnSecondAndFirstLevelDomain(): string {
  const parts = window.location.hostname.split('.');
  if (parts.length >= 2) {
    return parts[parts.length - 2] + '.' + parts[parts.length - 1];
  }
  return parts[0];
}
