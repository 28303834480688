import { RepositoryPanel, useSetupWizardStore } from '@/stores/setup-wizard';
import { useNewProjectsStore } from '@/stores/new-project';
import { useMasterDataStore } from '@/stores/master-data';
import { useAccountInfoStore } from '@/stores/account-info';

export type RuleResult = boolean | string;
export type ValidationResult = { valid: boolean; errors: string[] };
export type Rule<T = RuleResult> = (context: RuleContext) => T;
export type CurriedRule = () => RuleResult;

export interface Rules {
  [key: string]: Rule | Rule[];
}

export interface RuleContext {
  setupWizard: ReturnType<typeof useSetupWizardStore>;
  newProject: ReturnType<typeof useNewProjectsStore>;
  masterData: ReturnType<typeof useMasterDataStore>;
}

export function ruleContext(): RuleContext {
  return {
    setupWizard: useSetupWizardStore(),
    newProject: useNewProjectsStore(),
    masterData: useMasterDataStore(),
  };
}

export function rule(expect: Rule<RuleResult | unknown>, error: string): Rule {
  return (context) => (expect(context) ? true : error);
}

export function flatRules(rules: Rules): Rule[] {
  return Object.values(rules).reduce((flat: Rule[], rule) => {
    const arr = Array.isArray(rule) ? rule : [rule];
    flat.push(...arr);
    return flat;
  }, []);
}

export function check(context: RuleContext, rules: Rules): RuleResult[] {
  return flatRules(rules).map((rule) => rule(context));
}

export function curry(context: RuleContext, rules: Rules): CurriedRule[] {
  return flatRules(rules).map((rule) => () => rule(context));
}

export function validate(context: RuleContext, rules: Rules): ValidationResult {
  const checks = check(context, rules);
  return {
    valid: checks.every((result) => result && typeof result !== 'string'),
    errors: checks.filter((result) => typeof result === 'string') as string[],
  };
}

export const repositoryRules: Rules = {
  // Hacky way to check if one of the panels is selected, in case mandatory prop gets removed from v-expansion-panels
  openPanel: rule(
    ({ setupWizard }) =>
      [RepositoryPanel.Own, RepositoryPanel.ThirdParty].includes(setupWizard.selectedRepositoryPanel),
    'setup.forms.errors.requiredInput'
  ),
  projectName: rule(({ newProject }) => newProject.projectName?.length, 'setup.forms.errors.requiredInput'),
  ownRepository: rule(
    ({ setupWizard }) =>
      setupWizard.isCopyingRepository ||
      (setupWizard.ownRepository.repo?.length && setupWizard.ownRepository.branch?.length),
    'setup.forms.errors.requiredInput'
  ),
  thirdPartyRepository: rule(
    ({ setupWizard }) =>
      !setupWizard.isCopyingRepository ||
      (setupWizard.thirdPartyRepository.source?.repo &&
        setupWizard.thirdPartyRepository.source?.owner &&
        setupWizard.thirdPartyRepository.destination.repository.repo),
    'setup.forms.errors.requiredInput'
  ),
};

export const frameworkRules: Rules = {
  projectType: rule(({ setupWizard }) => {
    const accountInfoStore = useAccountInfoStore();
    return (
      setupWizard.selectedProjectType &&
      accountInfoStore.hasFreePackage(setupWizard.selectedProjectType) &&
      setupWizard.selectedDetectionIndex >= 0
    );
  }, 'setup.forms.errors.requiredInput'),
};

export const buildRules: Rules = {
  // Every runtime should have a version
  runtimeVersion: rule(
    ({ newProject }) =>
      newProject.buildSteps.length === 0 || newProject.buildSteps.every(({ runtime }) => !runtime || runtime.version),
    'setup.forms.errors.requiredInput'
  ),
  // Every build tool should have a version
  buildToolVersion: rule(
    ({ newProject }) =>
      newProject.buildSteps.length === 0 ||
      newProject.buildSteps.every(({ buildTool }) => !buildTool || buildTool.version),
    'setup.forms.errors.requiredInput'
  ),
  deploymentFolder: rule(
    ({ newProject }) => newProject.deployStep?.deploymentFolder,
    'setup.forms.errors.requiredInput'
  ),
};
