import _ from 'lodash';
import { defineStore } from 'pinia';
import { accountApiClient, metadataApiClient, projectTypesApiClient } from '@/plugins/axios';
import { MasterDataState, ProjectType as ProjectTypeModel } from '@/model/store';
import { ACCOUNT_ME, PINIA_PERSIST_KEY } from '@/utils/const';

const storeId = 'master-data';

export const useMasterDataStore = defineStore(storeId, {
  state: (): MasterDataState => ({
    phpVersions: [],
    buildSteps: [],
    projectTypes: [],
    upsellInformation: undefined,
  }),
  getters: {
    projectTypeByName(state) {
      return (name: string) => {
        return _.find(state.projectTypes, { name });
      };
    },
  },
  actions: {
    async bootstrap() {
      await Promise.allSettled([this.requestPhpVersions(), this.requestBuildSteps(), this.requestProjectTypes()]);
    },
    async requestPhpVersions(): Promise<void> {
      if (this.phpVersions.length) {
        return;
      }
      await metadataApiClient
        .getAvailableVersions('php')
        .then(({ data }) => {
          this.phpVersions = data;
        })
        .catch(console.error);
    },
    async requestBuildSteps(): Promise<void> {
      if (this.buildSteps.length) {
        return;
      }
      await metadataApiClient
        .getBuildStepDescriptions()
        .then(({ data }) => {
          this.buildSteps = data;
        })
        .catch(console.error);
    },
    async requestProjectTypes(): Promise<void> {
      if (this.projectTypes.length) {
        return;
      }
      await projectTypesApiClient
        .getProjectTypes({
          pageSize: 100,
        })
        .then(({ data }) => {
          const projectTypes = data.values.filter(({ name }) =>
            [ProjectTypeModel.s, ProjectTypeModel.l, ProjectTypeModel.php].includes(name as ProjectTypeModel)
          );
          this.projectTypes = _.sortBy(projectTypes, 'stagingDeploymentCount');
        })
        .catch(console.error);
    },
    async getCustomerShop(tariffName: string) {
      if (this.upsellInformation === undefined) {
        await accountApiClient
          .getUpsellInformation(ACCOUNT_ME)
          .then(({ data }) => {
            this.upsellInformation = data;
          })
          .catch(console.error);
      }
      return _.get(this.upsellInformation?.projectTypeShopLinks, tariffName);
    },
    getPackageWithRequiredFeatures(required: string[]) {
      return this.projectTypes.filter((p) => _.difference(required, p.availableFeatures).length === 0);
    },
  },
  persist: {
    key: PINIA_PERSIST_KEY + '_' + storeId,
  },
});
