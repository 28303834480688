import { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import { GithubIdentity, GithubRepository, InstallationInput, Page, ProviderType } from 'ionos-space-api-v4';
import { GitHubState } from '@/model/store';
import { githubApiClient, tokenApiClient } from '@/plugins/axios';
import { useMessageStore } from '@/stores/message';
import { githubFullNameToGithubSourceRepository } from '@/model/storeApiConverter';
import { popPersistedActualLocation, rememberActualLocation, spliceOrPush } from '@/utils';
import { RawLocation, Route } from 'vue-router';
import router from '@/router/router';
import { EnvVars } from '@/utils/env-vars';
import { useAccountInfoStore } from '@/stores/account-info';
import { ACCOUNT_ME } from '@/utils/const';

export const useGitHubStore = defineStore('github', {
  state: (): GitHubState => ({
    githubRepositories: [],
    repositoryBranches: [],
    loaded: false,
    installation: undefined,
    tokenExists: undefined,
  }),
  getters: {
    getOwner(): string | undefined {
      return this.installation?.account.name;
    },
    getAccount(): GithubIdentity | undefined {
      return this.installation?.account;
    },
    internalInstallationId(): string | undefined {
      return this.installation?.id;
    },
    externalInstallationId(): string | undefined {
      return this.installation?.externalId;
    },
    fullName(): (repo: string, owner?: string) => string {
      return (repo: string, owner?: string) => {
        const accountName = owner || this.getAccount?.name;
        return accountName && repo ? `${accountName}/${repo}` : '';
      };
    },
    branchesForFullName(): (fullName: string) => string[] | undefined {
      return (fullName: string) => {
        const found = this.repositoryBranches.find((repo) => repo.fullName === fullName);
        return found?.branches;
      };
    },
    defaultBranchForRepo(): (repo: string) => string | undefined {
      return (repo: string) => {
        const fullName = this.fullName(repo);

        const found = this.githubRepositories.find((repo) => repo.fullName === fullName);
        return found?.defaultBranch;
      };
    },
    getInstallationStatusAsString(): string {
      if (this.isInstallationSuspended) {
        return 'suspended';
      } else if (this.isInstallationDeleted) {
        return 'deleted';
      } else if (this.isOauthTokenMissing) {
        return 'tokenDeleted';
      } else if (!this.hasNecessaryPermissions) {
        return 'needsNecessaryPermissions';
      }
      return 'none';
    },
    getInstallationUrl(): string {
      if (this.isInstalled) {
        if (this.isInstallationSuspended && this.externalInstallationId) {
          return EnvVars.getGithubManageInstallationUrl(this.externalInstallationId);
        } else if (this.isOauthTokenMissing) {
          return EnvVars.getGithubAuthorizationUrl();
        } else if (this.externalInstallationId) {
          return EnvVars.getGithubManageInstallationUrl(this.externalInstallationId);
        }
      }
      return EnvVars.getGithubNewInstallationUrl('installation');
    },
    isInstalled(): boolean {
      return !!this.getOwner;
    },
    isInstallationCompleted(): boolean {
      return this.isInstalled && !this.isInstallationSuspended;
    },
    isInstallationSuspended(): boolean {
      return !!this.installation?.suspended;
    },
    isInstallationDeleted(): boolean {
      const accountInfoStore = useAccountInfoStore();
      return !this.isInstalled && accountInfoStore.getProjectCount > 0;
    },
    isOauthTokenMissing(): boolean {
      return this.installation?.account.type === 'USER' && !this.tokenExists;
    },
    isFullyConnected(): boolean {
      return this.isInstallationCompleted && !this.isOauthTokenMissing && this.hasNecessaryPermissions;
    },
    hasNecessaryPermissions(): boolean {
      return !!this.installation?.necessaryPermissions;
    },
  },
  actions: {
    async loadGithubRepositories(): Promise<GithubRepository[]> {
      if (!this.internalInstallationId) return [];
      await githubApiClient
        .getRepositories(ACCOUNT_ME, this.internalInstallationId, {
          pageSize: 1000,
        })
        .then(({ data }: AxiosResponse<Page<GithubRepository>>) => {
          this.githubRepositories = data.values.sort((a: GithubRepository, b: GithubRepository) => {
            return a.inUse && b.inUse ? 0 : a.inUse && !b.inUse ? 1 : -1;
          });
        })
        .catch((error) => console.log(error));
      return this.githubRepositories;
    },
    loadOwnBranches(repo: string, force: boolean = false): Promise<string[] | undefined> {
      return this.loadBranches(this.fullName(repo), force);
    },
    async loadBranches(fullName: string, force: boolean = false): Promise<string[] | undefined> {
      if (!fullName) {
        return undefined;
      }
      if (force || !this.branchesForFullName(fullName)) {
        const repoParts = githubFullNameToGithubSourceRepository(fullName);
        if (repoParts?.owner && repoParts?.repo && this.internalInstallationId) {
          await githubApiClient
            .getBranches(ACCOUNT_ME, this.internalInstallationId, repoParts.owner, repoParts.repo, {
              pageSize: 1000,
            })
            .then(({ data }: AxiosResponse<Page<string>>) => {
              const repo = {
                fullName,
                branches: data.values,
              };
              spliceOrPush(this.repositoryBranches, repo, (item) => item.fullName === fullName);
            })
            .catch((error) => {
              useMessageStore().addError(error);
            });
        }
      }
      return this.branchesForFullName(fullName);
    },
    async loadInstallation(force?: boolean): Promise<boolean> {
      const accountInfoStore = useAccountInfoStore();
      const githubInstallationId = accountInfoStore.githubInstallationId;
      if (githubInstallationId) {
        if (!this.loaded || force) {
          await githubApiClient
            .getInstallation(ACCOUNT_ME, githubInstallationId)
            .then(async ({ data }: any) => {
              if (data?.account.type === 'USER') {
                const tokens = await tokenApiClient.getTokens(ACCOUNT_ME).then(({ data }: any) => data);
                this.tokenExists = !!tokens?.filter((value) => value.type === ProviderType.GITHUB).length;
              }
              this.installation = data;
            })
            .catch(() => {
              this.installation = undefined;
            })
            .finally(() => (this.loaded = true));
        }
      } else {
        this.installation = undefined;
      }
      return true;
    },
    connect(route: Route) {
      rememberActualLocation(route);
      router.push('github-connection');
    },
    continueAfterConnected() {
      const initialPath = popPersistedActualLocation();
      router.push(initialPath ? (initialPath as RawLocation) : '/');
    },
    deleteInstallation() {
      this.installation = undefined;
    },
    suspendInstallation() {
      if (this.installation) {
        this.installation.suspended = true;
      }
    },
    unsuspendInstallation() {
      this.loadInstallation(true);
    },
    async installApp(installation: InstallationInput) {
      await githubApiClient.createInstallation(ACCOUNT_ME, installation);
    },
    async authorize(code: string) {
      await tokenApiClient.createToken(ACCOUNT_ME, { type: ProviderType.GITHUB, code: code });
    },
  },
});
