
















import { defineComponent } from '@vue/composition-api';
import AppFooter from '@/components/shared/footer/Footer.vue';
import AppNavigation from '@/components/shared/navigation/Navigation.vue';
import { useAuthStore } from '@/stores/auth';

export default defineComponent({
  name: 'WelcomeLayout',
  components: { AppFooter, AppNavigation },
  setup() {
    const authStore = useAuthStore();

    const login = () => {
      authStore.goToLogin('login');
    };

    return {
      login,
    };
  },
});
