import { Branch, Project, ProjectType } from '@/model/store';

export function isPhpProject(project: Project): boolean {
  return project.projectType === ProjectType.php;
}

export function isPhpProjectByType(projectType: string): boolean {
  return projectType === ProjectType.php;
}

export function isStarterProjectByType(projectType: string): boolean {
  return projectType === ProjectType.s;
}

export function getRepositoryNameWithoutRepoOwner(name: string) {
  return name.substring(name.indexOf('/') + 1);
}

export function isAMultiDeploymentProject(project: Project): boolean {
  return project.productionDeploymentCount > 1;
}

export function branchCounter(branches: Branch[], countIf: (branch: Branch) => boolean) {
  return branches.filter((branch: Branch) => {
    return countIf(branch);
  }).length;
}
