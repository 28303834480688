import Vue from 'vue';
import router from '@/router/router';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { EnvVars } from '@/utils/env-vars';
import { useAuthStore } from '@/stores/auth';

const sentryURL = EnvVars.getIonosSpaceSentryUrl();
const allowedMarkets = ['US', 'CA', 'MX'];

export const useSentry = () => {
  const authStore = useAuthStore();
  const market = authStore.market as string;

  try {
    if (allowedMarkets.includes(market) && sentryURL) {
      Sentry.init({
        Vue,
        dsn: sentryURL,
        integrations: [
          new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ['ionos.space', /^\//],
          }),
        ],
        tracesSampleRate: 0.25,
      });
    }
  } catch (e) {
    console.error(e);
  }
};
