import { EventSourcePolyfill } from 'event-source-polyfill';
import { EnvVars } from '@/utils/env-vars';
import { useAuthStore } from '@/stores/auth';
import { useGitHubStore } from '@/stores/github';
import {
  BranchChange,
  BranchChangeType,
  ContractChange,
  ContractChangeType,
  DeploymentChange,
  DeploymentChangeType,
  InstallationChange,
  InstallationChangeType,
  ProjectChange,
  ProjectChangeType,
  ProviderType,
  TokenChange,
  TokenChangeType,
} from 'ionos-space-api-v4';
import { useProjectsStore } from '@/stores/projects';
import { useAccountInfoStore } from '@/stores/account-info';
import { ACCOUNT_ME } from '@/utils/const';

export default class EventSourceService {
  private eventSource!: EventSourcePolyfill;
  private projectsStore;
  private gitHubStore;
  private accountInfoStore;

  public init() {
    const authStore = useAuthStore();
    this.projectsStore = useProjectsStore();
    this.gitHubStore = useGitHubStore();
    this.accountInfoStore = useAccountInfoStore();

    if (!this.eventSource && authStore.market) {
      const eventSourceUrl = EnvVars.getEnventsource(authStore.market).replace('{accountId}', ACCOUNT_ME);
      this.eventSource = new EventSourcePolyfill(eventSourceUrl, {
        headers: {
          Authorization: 'Bearer ' + authStore.accessToken,
        },
      });

      this.addListeners();
    }
  }

  private addListeners() {
    if (!this.eventSource) {
      return;
    }

    Object.getOwnPropertyNames(Object.getPrototypeOf(this)).forEach((method: string) => {
      if (method.startsWith('handle')) {
        const type = method
          .substr(6)
          .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
          .substr(1);
        //@ts-ignore
        this.eventSource.addEventListener(type, ({ data }) => {
          const parsedData = JSON.parse(data);
          this[method](parsedData);
          if (process.env.NODE_ENV !== 'production') {
            console.group('SSE');
            console.log(method);
            console.log(parsedData);
            console.groupEnd();
          }
        });
      }
    });
  }

  private async handleDeploymentChange(change: DeploymentChange) {
    switch (change.type) {
      case DeploymentChangeType.DEPLOYMENT_STATE:
      case DeploymentChangeType.NAME:
      case DeploymentChangeType.DATABASE:
      case DeploymentChangeType.WEBSPACE:
        await this.projectsStore.loadDeployment(change.projectId, change.branchId, change.deploymentId, {
          force: true,
        });
        break;
      case DeploymentChangeType.DOMAIN_CONNECT:
      case DeploymentChangeType.DOMAIN_DISCONNECT:
        await this.projectsStore.loadDeployment(change.projectId, change.branchId, change.deploymentId, {
          force: true,
        });
        await this.projectsStore.loadProject(change.projectId);
        break;
      case DeploymentChangeType.CREATE:
        await this.projectsStore.loadDeployment(change.projectId, change.branchId, change.deploymentId, {
          force: true,
        });
        await this.projectsStore.loadBranch(change.projectId, change.branchId);
        await this.projectsStore.loadProject(change.projectId);
        break;
      case DeploymentChangeType.DELETE:
        await this.projectsStore.removeDeployment(change.projectId, change.branchId, change.deploymentId);
        await this.projectsStore.loadBranch(change.projectId, change.branchId);
        await this.projectsStore.loadProject(change.projectId);
        break;
    }
  }

  private handleContractChange(change: ContractChange) {
    switch (change.type) {
      case ContractChangeType.LOCK:
      case ContractChangeType.PROJECT_TYPES:
      case ContractChangeType.UNLOCK:
        this.accountInfoStore.reloadAccountInfo();
        break;
      case ContractChangeType.GITHUB_CONNECTION:
        this.accountInfoStore.reloadAccountInfo();
        this.gitHubStore.loadInstallation(true);
        break;
    }
  }

  private async handleBranchChange(change: BranchChange) {
    switch (change.type) {
      case BranchChangeType.BUILD_STATE:
      case BranchChangeType.DELETE:
      case BranchChangeType.UN_DELETE:
      case BranchChangeType.WORKFLOW_PRESENT:
      case BranchChangeType.CREATE:
      case BranchChangeType.DEPLOYMENT_STATE:
        this.projectsStore.loadBranch(change.projectId, change.branchId);
        break;
      case BranchChangeType.PURGE:
        await this.projectsStore.removeBranch(change.projectId, change.branchId);
        await this.projectsStore.loadProject(change.projectId);
        break;
      default:
        break;
    }
  }

  private handleGithubInstallationChange(change: InstallationChange) {
    switch (change.type) {
      case InstallationChangeType.DELETE: {
        this.gitHubStore.deleteInstallation();
        break;
      }
      case InstallationChangeType.SUSPEND: {
        this.gitHubStore.suspendInstallation();
        break;
      }
      case InstallationChangeType.UNSUSPEND: {
        this.gitHubStore.unsuspendInstallation();
        break;
      }
    }
  }

  private async handleTokenChange(change: TokenChange) {
    if (change.providerType === ProviderType.GITHUB && change.type === TokenChangeType.REVOKE) {
      await this.accountInfoStore.reloadAccountInfo();
      await this.gitHubStore.loadInstallation(true);
    }
  }

  private async handleProjectChange(change: ProjectChange) {
    switch (change.type) {
      case ProjectChangeType.CREATE:
        await this.projectsStore.loadProject(change.projectId);
        await this.projectsStore.loadListOfProjects(true);
        break;
      case ProjectChangeType.PROJECT_TYPE:
      case ProjectChangeType.LOCK:
      case ProjectChangeType.REPO_RENAME:
      case ProjectChangeType.UNLOCK:
      case ProjectChangeType.AUTOMATIC_DEPLOYMENT:
      case ProjectChangeType.LOSE_PERMISSION:
      case ProjectChangeType.RESTORE_PERMISSION:
      case ProjectChangeType.NAME:
      case ProjectChangeType.DELETE:
        await this.projectsStore.loadProject(change.projectId);
        break;
      case ProjectChangeType.PURGE:
        this.projectsStore.removeProject(change.projectId);
        break;
    }
  }
}
