
































import AppExitSetupDialog from '@/components/wizard/ExitSetupDialog.vue';
import { SetupWizardService } from '@/services/setup-wizard-service';
import { useSetupWizardStore } from '@/stores/setup-wizard';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { isPhpProjectByType } from '@/utils/projects';
import { SETUP_BUILD, SETUP_DEPLOYMENT } from '@/utils/const';
import { createProject as newProject } from '@/services/setup-wizard-service';

export default defineComponent({
  name: 'WizardFooter',
  components: {
    AppExitSetupDialog,
  },
  setup() {
    const store = useSetupWizardStore();
    const creatingProject = ref(false);

    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    const loading = computed<boolean>(() => store.loading);
    const prevStep = computed<string>(() => SetupWizardService.prevStep?.name || '');
    const nextStep = computed<string>(() => SetupWizardService.nextStep?.name || '');
    const canGoNext = computed<boolean>(() =>
      nextStep.value && !loading.value ? SetupWizardService.validateRoutes() : false
    );
    const isLastStep = computed<boolean>(() => SetupWizardService.isLastStep);

    const goBack = () => {
      const prev = prevStep.value;
      const projectType = store.selectedProjectType || '';
      if (prev) {
        if (isPhpProjectByType(projectType)) {
          SetupWizardService.navigate(prev);
        } else {
          if (prev === SETUP_DEPLOYMENT) {
            SetupWizardService.navigate(SETUP_BUILD);
          } else {
            SetupWizardService.navigate(prev);
          }
        }
      }
      scrollToTop();
    };

    const goNext = () => {
      const next = nextStep.value;
      if (next) {
        SetupWizardService.navigate(next);
      }
      scrollToTop();
    };

    const createProject = async (): Promise<void> => {
      creatingProject.value = true;
      await newProject();
      creatingProject.value = false;
    };

    return {
      creatingProject,
      loading,
      prevStep,
      nextStep,
      canGoNext,
      isLastStep,
      goBack,
      goNext,
      createProject,
    };
  },
});
