import axios, { AxiosRequestConfig } from 'axios';
import { EnvVars } from '@/utils/env-vars';
import {
  AxiosIonosSpaceAccountApiClient as AccountApiClient,
  AxiosIonosSpaceAccountInfoApiClient as AccountInfoApiClient,
  AxiosIonosSpaceBranchApiClient as BranchApiClient,
  AxiosIonosSpaceDeploymentApiClient as DeploymentApiClient,
  AxiosIonosSpaceDetectionApiClient as DetectionApiClient,
  AxiosIonosSpaceGithubApiClient as GithubApiClient,
  AxiosIonosSpaceMetadataApiClient as MetadataApiClient,
  AxiosIonosSpaceProjectApiClient as ProjectApiClient,
  AxiosIonosSpaceProjectTypesApiClient as ProjectTypesApiClient,
  AxiosIonosSpaceTokensApiClient as TokensApiClient,
  AxiosIonosSpaceUserSessionApiClient as UserSessionApiClient,
} from 'ionos-space-api-v4';
import { AxiosRestApplicationClient as DirectoryApiClient } from 'waas-directory-service-api';

import { useAuthStore } from '@/stores/auth';
import { useProjectsStore } from '@/stores/projects';

export class AxiosRequestCancelError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AxiosRequestCancelError';
  }
}

// AUTH API
export const authInstance = axios.create({
  baseURL: EnvVars.getOauthUrl(),
});

// IONOS SPACE API
const ionosSpaceInstance = axios.create();
ionosSpaceInstance.interceptors.request.use(addAccessTokenToRequestAuthorizationHeader);

// CRAWLER API
const crawlerInstance = axios.create();
crawlerInstance.interceptors.request.use(addAccessTokenToRequestAuthorizationHeader);

// WAAS DIRECTORY SESSION API
const directoryInstance = axios.create();
directoryInstance.interceptors.request.use(addDirectorySessionTokenToRequestSessionHeader);
directoryInstance.interceptors.response.use(undefined, catchDirectorySessionTokenRequest);

function addAccessTokenToRequestAuthorizationHeader(config: AxiosRequestConfig) {
  const authStore = useAuthStore();

  if (authStore.loggedIn()) {
    // @ts-ignore
    config.headers.Authorization = `Bearer ${authStore.accessToken}`;
  } else {
    authStore.handleSessionTimeout();
    throw new AxiosRequestCancelError('Request cancelled as user session timed out');
  }
  return config;
}

async function addDirectorySessionTokenToRequestSessionHeader(config: AxiosRequestConfig) {
  const projectsStore = useProjectsStore();

  let directorySessionToken = projectsStore.directorySessionToken();
  if (!directorySessionToken) {
    await projectsStore.refreshDirectorySessionToken();
    directorySessionToken = projectsStore.directorySessionToken();
  }
  config.headers!['x-session'] = directorySessionToken.token || '';
  return config;
}

async function catchDirectorySessionTokenRequest(error) {
  const projectsStore = useProjectsStore();

  if (error.response && [400, 401].includes(error.response.status) && !error.config.__alreadyFailed) {
    // Refresh token
    error.config.__alreadyFailed = true;
    await projectsStore.refreshDirectorySessionToken();
    return directoryInstance(error.config);
  }
  return Promise.reject(error);
}

const ionosSpaceApiUrl = '';
export const accountApiClient = new AccountApiClient(ionosSpaceApiUrl, ionosSpaceInstance);
export const accountInfoApiClient = new AccountInfoApiClient(ionosSpaceApiUrl, ionosSpaceInstance);
export const branchApiClient = new BranchApiClient(ionosSpaceApiUrl, ionosSpaceInstance);
export const deploymentApiClient = new DeploymentApiClient(ionosSpaceApiUrl, ionosSpaceInstance);
export const detectionApiClient = new DetectionApiClient(ionosSpaceApiUrl, ionosSpaceInstance);
export const githubApiClient = new GithubApiClient(ionosSpaceApiUrl, ionosSpaceInstance);
export const metadataApiClient = new MetadataApiClient(ionosSpaceApiUrl, ionosSpaceInstance);
export const projectApiClient = new ProjectApiClient(ionosSpaceApiUrl, ionosSpaceInstance);
export const projectTypesApiClient = new ProjectTypesApiClient(ionosSpaceApiUrl, ionosSpaceInstance);
export const tokenApiClient = new TokensApiClient(ionosSpaceApiUrl, ionosSpaceInstance);
export const userSessionApiClient = new UserSessionApiClient(ionosSpaceApiUrl, ionosSpaceInstance);

const directoryApiUrl = EnvVars.getDirectoryApiUrl();
export const directoryApiClient = new DirectoryApiClient(directoryApiUrl, directoryInstance);

export function setBaseUrls() {
  const authStore = useAuthStore();

  if (authStore.market) {
    const market = authStore.market;
    ionosSpaceInstance.defaults.baseURL = EnvVars.getIonosSpaceApiUrl(market);
  }
}
