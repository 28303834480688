import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import { RouteConfig } from 'vue-router/types/router';
import * as _ from 'lodash';
import { LOCAL_STORAGE_KEY_MARKET } from '@/services/auth-service';
import { SetupWizardService } from '@/services/setup-wizard-service';
import { rememberActualLocation } from '@/utils';
import Auth from '@/views/auth/Auth.vue';
import CriticalError from '@/views/CriticalError.vue';
import Logout from '@/views/auth/Logout.vue';
import LoggedInLayout from '@/views/layout/LoggedInLayout.vue';
import WelcomeLayout from '@/views/layout/WelcomeLayout.vue';
import {
  SETUP_REPOSITORY,
  SETUP_FRAMEWORK,
  SETUP_BUILD,
  SETUP_RUNTIME,
  SETUP_DEPLOYMENT,
  SETUP_SUMMARY,
} from '@/utils/const';
import { useMasterDataStore } from '@/stores/master-data';
import { useAuthStore } from '@/stores/auth';
import { useGitHubStore } from '@/stores/github';
import { useAccountInfoStore } from '@/stores/account-info';
import { setBaseUrls } from '@/plugins/axios';

Vue.use(VueRouter);

async function reloadAccountInfoAndGithubInstallation(): Promise<boolean> {
  const accountInfoStore = useAccountInfoStore();
  const githubStore = useGitHubStore();

  await accountInfoStore.reloadAccountInfo();
  await githubStore.loadInstallation(true);
  return true;
}

function beforeEnterWizardStep(to: Route, from: Route, next: Function) {
  return SetupWizardService.beforeEnter(to.name || '', from.name || '', next);
}

const routes: RouteConfig[] = [
  {
    path: '/',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        component: LoggedInLayout,
        beforeEnter: function (to: Route, from: Route, next: Function) {
          const accountInfoStore = useAccountInfoStore();
          const githubStore = useGitHubStore();

          if (accountInfoStore.showWelcome) {
            rememberActualLocation(to);
            next({ name: 'welcome' });
          } else if (!githubStore.isFullyConnected && 0 === accountInfoStore.getProjectCount) {
            githubStore.connect(to);
          } else {
            next();
          }
        },
        children: [
          {
            path: '/',
            name: 'dashboard',
            components: {
              default: () => import('@/views/Dashboard.vue'),
            },
            beforeEnter: function (to: Route, from: Route, next: Function) {
              const accountInfoStore = useAccountInfoStore();
              accountInfoStore.getProjectCount! > 0 ? next() : next('/setup');
            },
          },
          {
            path: '/setup',
            components: {
              default: () => import('@/views/wizard/SetupWrapper.vue'),
            },
            meta: {
              setup: true,
            },
            beforeEnter(to: Route, from: Route, next: Function) {
              const githubStore = useGitHubStore();
              if (!githubStore.isFullyConnected) {
                githubStore.connect(to);
              }
              return beforeEnterWizardStep(to, from, next);
            },
            children: [
              {
                path: '',
                name: SETUP_REPOSITORY,
                components: {
                  default: () => import('@/views/wizard/repository/ChooseSource.vue'),
                },
                beforeEnter: beforeEnterWizardStep,
              },
              {
                path: 'select-framework',
                name: SETUP_FRAMEWORK,
                components: {
                  default: () => import('@/views/wizard/repository/ChooseFramework.vue'),
                },
                beforeEnter: beforeEnterWizardStep,
              },
              {
                path: 'configure-build',
                name: SETUP_BUILD,
                components: {
                  default: () => import('@/views/wizard/build/ConfigureBuild.vue'),
                },
                beforeEnter: beforeEnterWizardStep,
              },
              {
                path: 'deployment',
                name: SETUP_DEPLOYMENT,
                components: {
                  default: () => import('@/views/wizard/deployment/Index.vue'),
                },
                beforeEnter: beforeEnterWizardStep,
              },
              {
                path: 'set-runtime',
                name: SETUP_RUNTIME,
                components: {
                  default: () => import('@/views/wizard/runtime/Index.vue'),
                },
                beforeEnter: beforeEnterWizardStep,
              },
              {
                path: 'summary',
                name: SETUP_SUMMARY,
                components: {
                  default: () => import('@/views/wizard/summary/Summary.vue'),
                },
                beforeEnter: beforeEnterWizardStep,
              },
            ],
          },
          {
            path: '/detail/:projectId',
            name: 'detail',
            meta: {
              details: true,
            },
            components: {
              default: () => import('@/views/ProjectDetails.vue'),
            },
          },
          {
            path: '/files/:projectId/:branchId/:deploymentId/:path(.*)',
            name: 'project-files',
            components: {
              default: () => import('@/views/ProjectFiles.vue'),
            },
          },
        ],
      },
      {
        path: '/',
        component: WelcomeLayout,
        children: [
          {
            path: '/welcome',
            name: 'welcome',
            component: () => import('@/views/Welcome.vue'),
          },
          {
            path: '/github-connection',
            name: 'github-connection',
            component: () => import('@/views/provider/GithubConnection.vue'),
          },
          {
            path: '/github/install',
            beforeEnter: function (to: Route, from: Route, next: Function) {
              //app is installed or updated
              const githubStore = useGitHubStore();
              if (_.get(to, 'query.installation_id')) {
                if (_.get(to, 'query.setup_action') === 'install') {
                  githubStore
                    .installApp({
                      // @ts-ignore
                      code: _.get(to, 'query.code', null),
                      // @ts-ignore
                      externalId: _.get(to, 'query.installation_id', null),
                    })
                    .finally(async () => {
                      await reloadAccountInfoAndGithubInstallation();
                      githubStore.continueAfterConnected();
                    });
                } else if (_.get(to, 'query.setup_action') === 'update') {
                  githubStore
                    .installApp({
                      // @ts-ignore
                      code: _.get(to, 'query.code', null),
                      // @ts-ignore
                      externalId: _.get(to, 'query.installation_id', null),
                    })
                    .finally(async () => {
                      await reloadAccountInfoAndGithubInstallation();
                      if (_.get(to, 'query.state') === 'setup') {
                        next('/setup');
                      } else {
                        githubStore.continueAfterConnected();
                      }
                    });
                }
              } else {
                // @ts-ignore
                githubStore.authorize(_.get(to, 'query.code', null)).finally(async () => {
                  await reloadAccountInfoAndGithubInstallation();
                  githubStore.continueAfterConnected();
                });
              }
            },
          },
        ],
      },
    ],
  },
  {
    path: '/',
    component: WelcomeLayout,
    children: [
      {
        path: '/sign-up',
        name: 'sign-up',
        component: () => import('@/views/auth/SignUp.vue'),
        beforeEnter: function (to: Route, from: Route, next: Function) {
          const authStore = useAuthStore();
          if (authStore.loggedIn()) {
            next('dashboard');
          } else {
            next();
          }
        },
      },
    ],
  },
  {
    path: '/auth',
    component: Auth,
  },
  {
    path: '/error',
    component: CriticalError,
  },
  {
    path: '/logout',
    component: Logout,
  },
  {
    path: '/market/:market',
    beforeEnter: function (to: Route, from: Route, next: Function) {
      localStorage.setItem(LOCAL_STORAGE_KEY_MARKET, to.params.market.toUpperCase());
      next('/');
    },
  },
  { path: '*', redirect: '/' },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const authStore = useAuthStore();
    const githubStore = useGitHubStore();

    if (authStore.loggedIn()) {
      setBaseUrls();
      Vue.prototype.$eventSourceService.init();
      //initialize MasterDataStore
      const masterDataStore = useMasterDataStore();
      await masterDataStore.bootstrap();
      const accountInfoStore = useAccountInfoStore();
      if (await accountInfoStore.hasAccountInfo()) {
        await githubStore.loadInstallation();
        next();
      } else {
        //@TODO Error-Page
        console.log('account not found');
      }
    } else {
      rememberActualLocation(to);
      authStore.goToLogin('none');
    }
  } else {
    if (to?.name && SetupWizardService.hasStep(to.name)) {
      beforeEnterWizardStep(to, from, next);
    }
    next();
  }
});

export default router;
