






import { computed, defineComponent } from '@vue/composition-api';
import { useVuetify } from '@/libs/hooks';

export default defineComponent({
  name: 'AppLogo',
  props: {
    isDetails: Boolean,
  },
  setup(props) {
    const vm = useVuetify();
    const darkMode = computed(() => vm.value.theme.dark);

    const whiteIcon = computed(() => props.isDetails || darkMode.value);

    return {
      darkMode,
      whiteIcon,
    };
  },
});
