




import { defineComponent } from '@vue/composition-api';
import { useAuthStore } from '@/stores/auth';

export default defineComponent({
  name: 'Logout',
  setup() {
    useAuthStore().logout();
  },
});
