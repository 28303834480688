














import { defineComponent, ref } from '@vue/composition-api';
import AppTariffUpgrade from '@/components/shared/tariffUpgrade/TariffUpgrade.vue';
import { useAccountInfoStore } from '@/stores/account-info';

export default defineComponent({
  name: 'AppTariffUpgradeDialog',
  components: { AppTariffUpgrade },
  setup() {
    const visible = ref<boolean>(true);
    const noFreeProjectAvailableForSetup = ref<boolean>(false);

    const dialog = {
      dialogRef: ref(undefined as any),
      open: (
        args: { noFreeProjectAvailableForSetup: boolean } = {
          noFreeProjectAvailableForSetup: false,
        }
      ) => {
        noFreeProjectAvailableForSetup.value = args.noFreeProjectAvailableForSetup;
        dialog.dialogRef.value.open();
      },
      close: () => {
        dialog.dialogRef.value.close();
      },
    };

    const upgradeTariff = (tariffName: string) => {
      useAccountInfoStore().upgradeTariff(tariffName);
      dialog.close();
    };

    return {
      ...dialog,
      visible,
      noFreeProjectAvailableForSetup,
      upgradeTariff,
    };
  },
});
