import { defineStore } from 'pinia';

class NotificationsStoreState {
  notification?: Notification = undefined;
}

export enum NotificationType {
  ERROR,
  SUCCESS,
}

class Notification {
  message: string = '';
  type: NotificationType = NotificationType.ERROR;
  time: number = 3000;

  constructor(message: string, type: NotificationType, time?: number) {
    this.message = message;
    this.type = type;
    if (time) {
      this.time = time;
    }
  }
}

export const useNotificationStore = defineStore('notifications', {
  state: (): NotificationsStoreState => ({
    notification: undefined,
  }),
  actions: {
    show(notification: Notification) {
      this.notification = notification;
      setTimeout(
        () => {
          this.notification = undefined;
        },
        notification.time ? notification.time : 3000
      );
    },
  },
});
