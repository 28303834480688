import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import {
  Branch as ApiBranch,
  BranchOverview as ApiBranchOverview,
  BuildStep as ApiBuildStep,
  BuildStepDescription as ApiBuildStepDescription,
  BuildStepInput as ApiBuildStepInput,
  DeployStep as ApiDeployStep,
  DeployStepInput as ApiDeployStepInput,
  DestinationRepositoryInput as ApiDestinationRepositoryInput,
  DetectionBaseType as ApiDetectionBaseType,
  DetectionResult as ApiDetectionResult,
  DirectorySessionToken as ApiDirectorySessionToken,
  GithubRepositoryInput as ApiGithubRepositoryInput,
  PhpMyAdminSession as ApiPhpMyAdminSession,
  Project as ApiProject,
  ProjectInput as ApiProjectInput,
  ProjectOverview as ApiProjectOverview,
  ProviderType as ApiProviderType,
  RepositoryInputUnion as ApiRepositoryInputUnion,
  RuntimeStep as ApiRuntimeStep,
  RuntimeStepInput as ApiRuntimestepInput,
  TemplateFile as ApiTemplateFile,
  Variable as ApiVariable,
  Deployment as ApiDeployment,
  DeploymentOverview as ApiDeploymentOverview,
} from 'ionos-space-api-v4';
import {
  Branch,
  BranchState,
  BuildStep,
  Deployment,
  DetectionResult,
  NewProjectState,
  Project,
  ProjectDeploymentToken,
  RuntimeStep,
  TemplateFile,
  VariableInput,
  VariableInputs,
} from '@/model/store';
import { githubHttpsUrlRegex, githubSshUrlRegex } from '@/utils/const';
import { useMasterDataStore } from '@/stores/master-data';
import { urlWithoutProtocol } from '@/utils';

export function projectToStoreProject(project: ApiProject): Project {
  return {
    id: project.id,
    name: project.name,
    deleted: project.deleted,
    defaultDomainRootPath: project.defaultDomainRootPath,
    permissionLost: project.permissionLost,
    gitRepository: project.gitRepository,
    productionBranchId: project.productionBranchId,
    productionDeploymentCount: project.productionDeploymentCount,
    stagingDeploymentCount: project.stagingDeploymentCount,
    maxStagingDeploymentCount: project.maxStagingDeploymentCount,
    providerType: stringToEnumValue(ApiProviderType, project.providerType),
    projectType: project.projectType,
    automaticDeploymentEnabled: project.automaticDeploymentEnabled,
    locked: project.locked,
    defaultPhpVersion: project.defaultPhpVersion,
    databaseSettings: project.databaseSettings,
    visitorStatisticsEnabled: project.visitorStatisticsEnabled,
    spawningEnabled: project.spawningEnabled,
    domains: project.domains ?? [],
    siteUrls: project.siteUrls ?? [],
    lastChangedDate: project.lastChangedDate,
    branches: [],
    productionBranch: undefined,
    _projectDomain: urlWithoutProtocol(
      project.domains.length ? project.domains[0] : project.siteUrls.length ? project.siteUrls[0] : ''
    ),
    _detailsLoaded: true,
    _productionBranchLoaded: false,
    _branchesLoaded: false,
    _freshlyCreated: false,
  };
}

export function overviewProjectToStoreProject(project: ApiProjectOverview): Project {
  return {
    id: project.id,
    name: project.name,
    deleted: project.deleted,
    defaultDomainRootPath: '',
    permissionLost: project.permissionLost,
    gitRepository: project.gitRepository,
    productionBranchId: project.productionBranchId,
    productionDeploymentCount: project.productionDeploymentCount,
    stagingDeploymentCount: project.stagingDeploymentCount,
    maxStagingDeploymentCount: 0,
    providerType: stringToEnumValue(ApiProviderType, project.providerType),
    projectType: project.projectType,
    locked: project.locked,
    spawningEnabled: project.spawningEnabled,
    domains: project.domains ?? [],
    siteUrls: project.siteUrls ?? [],
    lastChangedDate: project.lastChangedDate,
    branches: [],
    productionBranch: undefined,
    _projectDomain: urlWithoutProtocol(
      project.domains.length ? project.domains[0] : project.siteUrls.length ? project.siteUrls[0] : ''
    ),
    _detailsLoaded: false,
    _productionBranchLoaded: false,
    _branchesLoaded: false,
    _freshlyCreated: false,
  };
}

function branchBuildStateToBranchState(branch: ApiBranch): BranchState {
  const buildState = branch.buildState?.state;
  if (branch.deleted) {
    return BranchState.ON_GITHUB_DELETE;
  } else if (!branch.workflowPresent) {
    return BranchState.WORKFLOW_MISSING;
  } else if (
    buildState &&
    buildState.toString() === BranchState.FAILED &&
    branch.buildState?.lastBuildDate === undefined
  ) {
    return BranchState.INITIAL_BUILD_FAILED;
  } else if (buildState) {
    return stringToEnumValue(BranchState, buildState) as BranchState;
  }
  return BranchState.UNKNOWN;
}

export function overviewBranchToStoreBranch(projectId: string, branch: ApiBranchOverview): Branch {
  return {
    id: branch.id,
    name: branch.name,
    productionBranch: branch.productionBranch,
    webUrl: branch.webUrl,
    workflowPresent: branch.workflowPresent,
    deprecatedWorkflow: branch.deprecatedWorkflow,
    deleted: branch.deleted,
    deploymentCount: branch.deploymentCount,
    _deploymentsLoaded: false,
    _projectId: projectId,

    buildState: {
      state: branchBuildStateToBranchState(branch),
      url: branch.buildState?.url,
      lastBuildDate: branch.buildState?.lastBuildDate,
    },
    deploymentState: branch.deploymentState,
    deployments: [],
  };
}

export function branchToStoreBranch(projectId: string, branch: ApiBranch): Branch {
  return overviewBranchToStoreBranch(projectId, branch);
}

export function overviewDeploymentToStoreDeployment(
  projectId: string,
  branchId: string,
  deployment: ApiDeploymentOverview
): Deployment {
  //@TODO dirty fix for php version mismatch from API (phpVersion vs. phpVersion of webspace)
  const masterDataStore = useMasterDataStore();
  if ('8.0' === deployment.webspace?.phpVersion && !masterDataStore.phpVersions.find((value) => value === '8.0')) {
    deployment.webspace.phpVersion = '8';
  }
  return {
    id: deployment.id,
    name: deployment.name,
    domain: deployment.domain,
    state: deployment.state,
    webspace: deployment.webspace
      ? {
          webspace: { ...deployment.webspace },
        }
      : undefined,
    database: deployment.database
      ? {
          database: { ...deployment.database },
        }
      : undefined,
    _detailsLoaded: false,
    _projectId: projectId,
    _branchId: branchId,
  };
}

export function deploymentToStoreDeployment(projectId: string, branchId: string, deployment: ApiDeployment) {
  //@TODO dirty fix for php version mismatch from API (phpVersion vs. phpVersion of webspace)
  const masterDataStore = useMasterDataStore();
  if (
    '8.0' === deployment.webspace.webspace.phpVersion &&
    !masterDataStore.phpVersions.find((value) => value === '8.0')
  ) {
    deployment.webspace.webspace.phpVersion = '8';
  }
  return {
    id: deployment.id,
    name: deployment.name,
    domain: deployment.domain,
    state: deployment.state,
    webspace: deployment.webspace,
    database: deployment.database,
    _detailsLoaded: true,
    _projectId: projectId,
    _branchId: branchId,
  };
}

export function apiDetectionResultToDetectionResult(detectionResult: ApiDetectionResult): DetectionResult {
  // TODO: Is this still necessary?
  if (!detectionResult.detectionBase.framework) {
    // There is a .ionos.yaml in the project
    detectionResult.detectionBase.type = ApiDetectionBaseType.IONOS_YAML;
    detectionResult.detectionBase.framework = {
      id: uuid(),
      // TODO: Replace hardcoded 'ionos.yaml' with variable or enum
      name: 'ionos.yaml',
    };
  }
  return {
    ...detectionResult,
    selectedProjectType: '',
  };
}

export function apiDeployStepToDeployStepInput(deployStep: ApiDeployStep): ApiDeployStepInput {
  return {
    ...deployStep,
    deploymentFolder: deployStep.deploymentFolder ? deployStep.deploymentFolder : '',
  };
}

export function apiBuildStepToBuildStep(buildStep: ApiBuildStep): BuildStep {
  // FIXME: Hack for composer
  if (buildStep.name === 'composer' && buildStep.buildTool && !buildStep.buildTool?.version) {
    buildStep.buildTool.version = 'latest';
  }
  return {
    ...buildStep,
    id: uuid(),
    variables: apiVariableInputsToVariableInputs(buildStep.variables),
  };
}

export function apiBuildStepDescriptionToBuildStep(description: ApiBuildStepDescription): BuildStep {
  const buildStep: BuildStep = {
    ...description,
    id: uuid(),
    variables: apiVariableInputsToVariableInputs(description.exampleVariables),
    commands: [],
    commandSuggestions: description.exampleCommands,
    complete: true,
  };
  // Fix edge-case where build step with runtime was added but could not be completed by API
  if (description.runtimeName && !buildStep.runtime) {
    buildStep.runtime = {
      name: description.runtimeName,
      version: '',
    };
  }
  return buildStep;
}

export function apiRuntimeStepToRuntimeStep(runtimeStep: ApiRuntimeStep): RuntimeStep {
  const templateFiles = runtimeStep ? runtimeStep.templateFiles.map(apiTemplateFileToTemplateFile) : [];
  return {
    domainRootPath: runtimeStep.domainRootPath,
    templateFiles,
    secrets: apiTemplateSecretsToVariableInputs(runtimeStep.secrets),
  };
}

export function apiTemplateSecretsToVariableInputs(secrets: Record<string, string>): VariableInput[] {
  return Object.entries(secrets).reduce((acc, [name, value]) => {
    if (name && value) {
      acc.push({ name, value, secret: true });
    }
    return acc;
  }, [] as VariableInput[]);
}

export function apiTemplateFileToTemplateFile(templateFile: ApiTemplateFile): TemplateFile {
  return {
    id: uuid(),
    content: templateFile.content,
    path: templateFile.path,
    completionType: templateFile.completionType,
  };
}

export function templateFileToApiTemplateFile(templateFile: TemplateFile): ApiTemplateFile {
  return _.omit(templateFile, ['id']);
}

export function buildStepToApiBuildStepInput(buildStep: BuildStep): ApiBuildStepInput {
  return {
    name: buildStep.name,
    buildToolVersion: buildStep.buildTool?.version,
    runtimeVersion: buildStep.runtime?.version,
    commands: buildStep.commands,
    variables: variableInputsToApiVariableInputs(buildStep.variables),
  };
}

export function runtimeStepToApiRuntimestep(runtimeStep: RuntimeStep): ApiRuntimestepInput | undefined {
  const templateFiles = runtimeStep.templateFiles
    .filter((templateFile: TemplateFile) => templateFile.content && templateFile.path)
    .map(templateFileToApiTemplateFile);

  return templateFiles.length
    ? {
        domainRootPath: runtimeStep.domainRootPath,
        templateFiles,
        secrets: variableInputsToApiTemplateStepSecrets(runtimeStep.secrets),
      }
    : undefined;
}

export function newProjectStateToApiGithubProjectInput(newProjectState: NewProjectState): ApiProjectInput {
  const buildSteps = Array.isArray(newProjectState.buildSteps)
    ? newProjectState.buildSteps.map(buildStepToApiBuildStepInput)
    : [];
  return {
    ...newProjectState,
    buildSteps,
    runtimeStep: newProjectState.runtimeStep ? runtimeStepToApiRuntimestep(newProjectState.runtimeStep) : undefined,
  };
}

export function apiVariableInputsToVariableInputs(
  inputs: Record<string, string> | Record<string, ApiVariable>
): VariableInput[] {
  return Object.entries(inputs).map(([name, value]) => {
    // Convert string to ApiVariable to assign polymorphic value properly
    const apiVariable: ApiVariable =
      typeof value === 'string'
        ? {
            value: value,
            secret: false,
          }
        : value;
    return {
      name,
      ...apiVariable,
    };
  });
}

export function variableInputsToApiTemplateStepSecrets(inputs: VariableInput[]): Record<string, string> {
  return inputs.reduce((acc, { name, value }) => {
    if (name) {
      acc[name] = value;
    }
    return acc;
  }, {} as Record<string, string>);
}

export function variableInputsToApiVariableInputs(inputs: VariableInput[]): VariableInputs {
  return inputs.reduce((acc, { name, value, secret }) => {
    if (name) {
      acc[name] = { value, secret };
    }
    return acc;
  }, {} as VariableInputs);
}

export const stringToEnumValue = <E extends Record<string, T>, T extends string>(enumObj: E, str: string): T =>
  enumObj[Object.keys(enumObj).filter((k) => enumObj[k] === str)[0]];

export function directorySessionTokenToStoreSessionToken(
  projectId: string,
  branchId: string,
  deploymentId: string,
  sessionToken: ApiDirectorySessionToken
): ProjectDeploymentToken {
  return {
    projectId,
    branchId,
    deploymentId,
    token: sessionToken.sessionId,
    expires: new Date(sessionToken.expireAt).getTime(),
  };
}

export function phpMyAdminSessionTokenToStoreSessionToken(
  projectId: string,
  branchId: string,
  deploymentId: string,
  sessionToken: ApiPhpMyAdminSession
): ProjectDeploymentToken {
  // ToDo: Change after MW change. expireAt should be set alltime
  const expireAt = sessionToken.expireAt
    ? new Date(sessionToken.expireAt).getTime()
    : new Date().setHours(new Date().getHours() + 4);
  return {
    projectId,
    branchId,
    deploymentId,
    token: sessionToken.url,
    expires: expireAt,
  };
}

export function githubUrlToGithubSourceRepository(url: string): ApiRepositoryInputUnion | null {
  const regex = url.startsWith('git@github') ? githubSshUrlRegex : githubHttpsUrlRegex;
  const match = url.match(regex);
  if (!match?.groups?.owner || !match?.groups?.repo) {
    return null;
  }
  const { owner, repo, ref } = match.groups;
  const source: ApiRepositoryInputUnion = {
    type: ApiProviderType.GITHUB,
    owner,
    repo: repo.replace(/\.git$/, ''),
  };
  if (ref) {
    source.branch = ref;
  }
  return new ApiGithubRepositoryInput(source);
}

export function githubFullNameToGithubSourceRepository(name: string): ApiGithubRepositoryInput | undefined {
  const split = _.split(name, '/');
  if (split.length === 2) {
    return new ApiGithubRepositoryInput({
      type: ApiProviderType.GITHUB,
      owner: split[0],
      repo: split[1],
    });
  }
  return undefined;
}

export function githubSourceRepositoryToGithubUrl(repository: ApiGithubRepositoryInput): string | undefined {
  if (repository.owner && repository.repo) {
    const append = repository.branch ? `/tree/${repository.branch}` : '.git';
    return `https://github.com/${repository.owner}/${repository.repo}${append}`;
  }
  return undefined;
}

export function emptyGithubRepository(): ApiGithubRepositoryInput {
  return new ApiGithubRepositoryInput({ repo: '', owner: '', type: ApiProviderType.GITHUB });
}

export function emptyGithubDestinationRepository(): ApiDestinationRepositoryInput {
  return new ApiDestinationRepositoryInput({ repository: emptyGithubRepository(), privateRepo: false });
}
