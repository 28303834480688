import { defineStore } from 'pinia';
import { Message, MessageSource, MessageStoreState, MessageType } from '@/model/store';
import { v4 as uuid } from 'uuid';

function createMessage(
  message: string,
  type: MessageType = MessageType.info,
  source: MessageSource = MessageSource.fe
): Message {
  return {
    id: uuid(),
    type,
    source,
    message,
  };
}

export const useMessageStore = defineStore('message', {
  state: (): MessageStoreState => ({
    messages: [],
  }),
  actions: {
    addMessage(message: string, type: MessageType, source?: MessageSource) {
      this.messages.push(createMessage(message, type, source));
    },
    addInfo(info: string, source?: MessageSource) {
      this.addMessage(info, MessageType.info, source);
    },
    addWarning(warning: string, source?: MessageSource) {
      this.addMessage(warning, MessageType.warning, source);
    },
    addError(error: Error | string, source?: MessageSource) {
      this.addMessage(`${error}`, MessageType.error, source);
    },
    removeMessage(id: string) {
      const index = this.messages.findIndex((msg) => msg.id === id);
      if (index > -1) {
        this.messages.splice(index, 1);
      }
    },
    removeMessages(type?: MessageType) {
      const clearAll = typeof type === 'undefined';
      this.messages = this.messages.filter((msg) => !clearAll && msg.type === type);
    },
  },
});
