import { ErrorHandlingState } from '@/model/store';
import { Error } from '@/utils/types';
import { defineStore } from 'pinia';

export const useErrorHandlingStore = defineStore('error-handling', {
  state: (): ErrorHandlingState => ({
    error: undefined,
  }),
  getters: {
    getError(state) {
      return state.error;
    },
  },
  actions: {
    setError(error: Error) {
      this.error = error;
    },
  },
});
