import { render, staticRenderFns } from "./MinimalNavigationAccount.vue?vue&type=template&id=3adc51d3&scoped=true"
import script from "./MinimalNavigationAccount.vue?vue&type=script&lang=ts"
export * from "./MinimalNavigationAccount.vue?vue&type=script&lang=ts"
import style0 from "./MinimalNavigationAccount.vue?vue&type=style&index=0&id=3adc51d3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3adc51d3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VList,VListItem,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle})
