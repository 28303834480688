import { defineStore } from 'pinia';
import {
  BuildStep,
  BuildStepChanges,
  DeployStepChanges,
  DeployStepType,
  NewProjectState,
  TemplateFile,
  RuntimeStep,
  VariableInput,
} from '@/model/store';
import { DeploymentConfiguration, DestinationRepositoryInput, ProviderType } from 'ionos-space-api-v4';
import { spliceOrPush } from '@/utils';
import { databaseSecrets, mailAccountSecrets } from '@/utils/const';
import { emptyGithubDestinationRepository } from '@/model/storeApiConverter';

function assignDeployStepChanges(
  deployStep?: DeploymentConfiguration,
  changes?: DeployStepChanges
): DeploymentConfiguration | undefined {
  if (!deployStep || !changes) {
    return undefined;
  }
  if (deployStep.excludes && Array.isArray(changes.excludes)) {
    deployStep.excludes = changes.excludes;
  }

  if (deployStep.remoteCommands.postDeployment && Array.isArray(changes.postDeployment)) {
    deployStep.remoteCommands.postDeployment = changes.postDeployment;
  }

  if (deployStep.remoteCommands.preDeployment && Array.isArray(changes.preDeployment)) {
    deployStep.remoteCommands.preDeployment = changes.preDeployment;
  }

  return deployStep;
}

function assignBuildStepChanges(buildStep: BuildStep, changes: BuildStepChanges): BuildStep {
  if (buildStep.runtime && typeof changes.runtimeVersion === 'string') {
    buildStep.runtime.version = changes.runtimeVersion;
  }
  if (buildStep.buildTool && typeof changes.buildToolVersion === 'string') {
    buildStep.buildTool.version = changes.buildToolVersion;
  }
  if (Array.isArray(changes.commands)) {
    buildStep.commands = changes.commands;
  }
  if (Array.isArray(changes.variables)) {
    buildStep.variables = changes.variables;
  }
  return buildStep;
}

export const useNewProjectsStore = defineStore('new-project', {
  state: (): NewProjectState => ({
    projectName: '',
    sourceRepository: undefined,
    destinationRepository: emptyGithubDestinationRepository(),
    buildSteps: [],
    runtimeStep: {
      domainRootPath: '',
      secrets: [
        {
          name: '',
          secret: false,
          editable: true,
          value: '',
        },
      ],
      templateFiles: [],
    },
    deployStep: {
      deploymentFolder: '',
      bootstrapConfig: {
        excludes: [''],
        remoteCommands: {
          preDeployment: [''],
          postDeployment: [''],
        },
      },
      recurringConfig: {
        excludes: [''],
        remoteCommands: {
          preDeployment: [''],
          postDeployment: [''],
        },
      },
      cronJobs: [],
    },
    defaultPhpVersion: undefined,
    automaticDeploymentEnabled: true,
    projectType: '',
    overwriteWorkflow: undefined,
    mailAccountEnabled: false,
    databaseEnabled: false,
    visitorStatisticsEnabled: true,
  }),
  getters: {
    databaseSecrets(): string[] {
      return Object.values(databaseSecrets);
    },
    mailAccountSecrets(): string[] {
      return Object.values(mailAccountSecrets);
    },
    buildStepIndex(state) {
      return (id: string) => state.buildSteps.findIndex((buildStep) => buildStep.id === id);
    },
  },
  actions: {
    resetRepositorySourceProperties() {
      this.sourceRepository = undefined;
      this.overwriteWorkflow = undefined;
      this.resetDestinationRepository();
    },
    setDestinationRepository(destinationRepository: DestinationRepositoryInput) {
      this.destinationRepository = destinationRepository;
      this.projectName = destinationRepository.repository.repo;
    },
    resetDestinationRepository() {
      this.setDestinationRepository({
        repository: {
          type: ProviderType.GITHUB,
          owner: '',
          repo: '',
          branch: '',
        },
        privateRepo: false,
      });
    },
    setBuildSteps(buildSteps: BuildStep[]) {
      this.buildSteps = buildSteps;
    },
    setBuildStep(buildStep: BuildStep) {
      spliceOrPush(this.buildSteps, buildStep, ({ id }) => id === buildStep.id);
    },
    setRuntimeStep(runtimeStep: RuntimeStep) {
      this.runtimeStep = runtimeStep;
    },
    setTemplateStepFiles(files: TemplateFile[]) {
      if (this.runtimeStep) {
        this.runtimeStep.templateFiles = files;
      }
    },
    setTemplateStepFile(file: TemplateFile) {
      if (this.runtimeStep && Array.isArray(this.runtimeStep.templateFiles)) {
        spliceOrPush(this.runtimeStep.templateFiles, file, ({ id }) => id === file.id);
      }
    },
    setRuntimeStepSecrets(secrets: VariableInput[]) {
      if (this.runtimeStep) {
        this.runtimeStep.secrets = secrets;
      }
    },
    setRuntimeDomainRootPath(domainRootPath: string) {
      if (this.runtimeStep) {
        this.runtimeStep.domainRootPath = domainRootPath;
      }
    },
    setDeploymentFolder(path: string) {
      if (this.deployStep) {
        this.deployStep.deploymentFolder = path;
      }
    },
    setDefaultPhpVersion(version: string | undefined) {
      this.defaultPhpVersion = version;
    },
    setDatabaseEnabled(enabled: boolean) {
      this.databaseEnabled = enabled;
    },
    setMailAccountEnabled(enabled: boolean) {
      this.mailAccountEnabled = enabled;
    },
    updateBuildStep(changes: BuildStepChanges) {
      const buildStep = this.buildSteps.find(({ id }) => id && id === changes.id);
      if (buildStep) {
        this.setBuildStep(assignBuildStepChanges(buildStep, changes));
      }
    },
    removeBuildStep(id: string) {
      const index = this.buildStepIndex(id);
      if (index > -1) {
        this.buildSteps.splice(index, 1);
      }
    },
    updateDeployStep(type: DeployStepType, change: DeployStepChanges) {
      const config = type === DeployStepType.Bootstrap ? 'bootstrapConfig' : 'recurringConfig';
      if (this.deployStep?.[config]) {
        this.deployStep[config] = assignDeployStepChanges(this.deployStep[config], change);
      }
    },
  },
});
