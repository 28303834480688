











import { defineComponent } from '@vue/composition-api';
import WizardFooter from '@/components/shared/footer/WizardFooter.vue';

export default defineComponent({
  name: 'AppFooter',
  components: { WizardFooter },
  props: {
    market: String,
  },
  setup() {
    return {};
  },
});
