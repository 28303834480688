import _ from 'lodash';
import router from '@/router/router';
import { useNewProjectsStore } from '@/stores/new-project';
import { useSetupWizardStore } from '@/stores/setup-wizard';
import { useMasterDataStore } from '@/stores/master-data';
import {
  apiBuildStepToBuildStep,
  apiDeployStepToDeployStepInput,
  apiRuntimeStepToRuntimeStep,
} from '@/model/storeApiConverter';
import { sleep } from '@/utils';
import {
  buildRules,
  frameworkRules,
  repositoryRules,
  Rule,
  ruleContext,
  Rules,
  validate,
  ValidationResult,
} from '@/services/validation-service';
import {
  SETUP_BUILD,
  SETUP_DEPLOYMENT,
  SETUP_FRAMEWORK,
  SETUP_REPOSITORY,
  SETUP_RUNTIME,
  SETUP_SUMMARY,
} from '@/utils/const';
import { ProjectTypeGroup } from '@/model/store';
import { useMessageStore } from '@/stores/message';
import { Feature } from 'ionos-space-api-v4';

type StepLoader<T = unknown> = StepLoaderPromise<T> | Partial<StepLoaderDefinition<T>>;
type StepLoaderPromise<T = unknown> = (context: NavigationContext) => Promise<T>;

interface StepLoaderDefinition<T = unknown> {
  loader: StepLoaderPromise<T>;
  if: Rule;
}

interface LookupTable {
  [key: string]: string;
}

export enum StepDirection {
  none,
  prev,
  curr,
  next,
}

export class Step {
  valid: boolean;
  direction: StepDirection = StepDirection.none;

  constructor(public name: string, public called: boolean = false, currentStep?: string) {
    this.valid = called && SetupWizardService.validateRoute(name).valid;
    if (currentStep) {
      this.direction = setupWizardRouter.direction(currentStep, name);
    }
  }
}

/**
 * Navigation context for step loader chain
 */
export class NavigationContext {
  public cancelled: boolean = false;
  public errors: string[] = [];
  public redirectTo: any = null;

  constructor(public verbose: boolean = process.env.NODE_ENV !== 'production') {}

  cancel(error: Error | string = '') {
    this.cancelled = true;
    this.errors.push(`${error}`);
    if (this.verbose) {
      console.error(error);
    }
  }
}

/**
 * Generates routes from lookup table and handles step loaders
 */
class SetupWizardRouter {
  static anyStep = '*';
  private loaders: { [name: string]: StepLoaderDefinition } = {};

  constructor(private lookup: LookupTable, public initial: string = SETUP_REPOSITORY) {}

  get allRoutes(): string[] {
    const routes: string[] = [];
    for (const step of this.steps(this.initial, false, true)) {
      routes.push(step);
    }
    return routes;
  }

  get routes(): string[] {
    const routes: string[] = [];
    for (const step of this.steps()) {
      routes.push(step);
    }
    return routes;
  }

  prev(name: string): string | undefined {
    const iterator = this.steps(name, true);
    iterator.next(); // Skip first iteration
    return iterator.next().value;
  }

  next(name: string): string | undefined {
    const iterator = this.steps(name);
    iterator.next(); // Skip first iteration
    return iterator.next().value;
  }

  isFirst(name: string): boolean {
    return typeof this.prev(name) === 'undefined';
  }

  isLast(name: string): boolean {
    return typeof this.next(name) === 'undefined';
  }

  hasRoute(name: string): boolean {
    return this.routes.includes(name);
  }

  async goTo(to: string): Promise<void> {
    const route = this.hasRoute(to) ? { name: to } : to;
    await router.push(route).catch(() => {});
  }

  direction(from: string, to: string): StepDirection {
    if (from === to) {
      return StepDirection.curr;
    }
    for (const step of this.steps(from)) {
      if (step === to) {
        return StepDirection.next;
      }
    }
    for (const step of this.steps(from, true)) {
      if (step === to) {
        return StepDirection.prev;
      }
    }
    return StepDirection.none;
  }

  addLoader(from: string, to: string, loader: StepLoader) {
    const loaderKey = SetupWizardRouter.loaderKey(from, to);
    if (typeof loader === 'object') {
      const promise = typeof loader.loader === 'function' ? loader.loader : async () => {};
      const rule = typeof loader.if === 'function' ? loader.if : undefined;
      this.loaders[loaderKey] = SetupWizardRouter.defineStepLoader(promise, rule);
    } else {
      this.loaders[loaderKey] = SetupWizardRouter.defineStepLoader(loader);
    }
  }

  addNextLoader(from: string, loader: StepLoader) {
    const to = this.next(from);
    if (to) {
      this.addLoader(from, to, loader);
    }
  }

  async load(from: string, to: string, context: NavigationContext = new NavigationContext()): Promise<void> {
    const definition = this.getLoader(from, to);
    if (definition) {
      await definition.loader(context).catch((error) => context.cancel(error));
    }
    if (context.redirectTo) {
      return this.goTo(context.redirectTo);
    } else if (!context.cancelled) {
      return this.goTo(to);
    }
  }

  getLoader(from: string, to: string): StepLoaderDefinition | undefined {
    return this.loaders[SetupWizardRouter.loaderKey(from, to)];
  }

  // Generator function to walk through steps in lookup tables
  // Use for() with iteration count to prevent infinite loops
  *steps(initial: string = this.initial, reverse: boolean = false, ignoreRule: boolean = false): Generator<string> {
    const lookup = reverse ? this.reversedLookup : this.lookup;
    const maxIterations = Object.keys(lookup).length + 1;
    let prevStep = SetupWizardRouter.anyStep;
    for (let step = initial, i = 0; step && i < maxIterations; i++) {
      const loader = this.getLoader(prevStep, step);
      if (ignoreRule || !loader || loader.if(ruleContext()) === true) {
        yield step;
      }
      prevStep = step;
      step = lookup[step];
    }
  }

  static defineStepLoader(loader: StepLoaderPromise, rule: Rule = () => true): StepLoaderDefinition {
    return {
      loader,
      if: rule,
    };
  }

  private get reversedLookup(): LookupTable {
    return Object.entries(this.lookup).reduce((acc, [from, to]) => {
      acc[to] = from;
      return acc;
    }, {});
  }

  private static loaderKey(from: string = SetupWizardRouter.anyStep, to: string = SetupWizardRouter.anyStep) {
    return `${from}->${to}`;
  }
}

/**
 * Provides router navigation, store interactions and validation
 */
export class SetupWizardService {
  static mainSetupSteps = [SETUP_REPOSITORY, SETUP_BUILD, SETUP_DEPLOYMENT, SETUP_RUNTIME];

  static get steps(): Step[] {
    const store = useSetupWizardStore();
    return setupWizardRouter.routes.map(
      (name) => new Step(name, store.calledSetupSteps.includes(name), SetupWizardService.currStep.name)
    );
  }

  static get mainSteps(): Step[] {
    return SetupWizardService.steps.filter((step) => SetupWizardService.mainSetupSteps.includes(step.name));
  }

  static get prevStep(): Step | undefined {
    const store = useSetupWizardStore();
    const prevStep = setupWizardRouter.prev(store.currentSetupStep);
    return prevStep ? new Step(prevStep, true) : undefined;
  }

  static get currStep(): Step {
    const store = useSetupWizardStore();
    return new Step(store.currentSetupStep, true);
  }

  static get nextStep(): Step | undefined {
    const store = useSetupWizardStore();
    const nextStep = setupWizardRouter.next(store.currentSetupStep);
    return nextStep ? new Step(nextStep) : undefined;
  }

  static hasStep(name: string): boolean {
    return setupWizardRouter.allRoutes.includes(name);
  }

  static calledStep(name: string): boolean {
    return useSetupWizardStore().calledSetupSteps.includes(name);
  }

  static get isLastStep(): boolean {
    const store = useSetupWizardStore();
    return setupWizardRouter.isLast(store.currentSetupStep);
  }

  static navigate(to: string): Promise<void> {
    const store = useSetupWizardStore();
    return setupWizardRouter.load(store.currentSetupStep, to);
  }

  static beforeEnter(to: string, from: string, next: Function): boolean {
    if (!to || !SetupWizardService.hasStep(to)) {
      next();
      return true;
    }
    if (from === to) {
      return false;
    }
    const store = useSetupWizardStore();
    if (setupWizardRouter.direction(from, to) !== StepDirection.prev) {
      for (const step of setupWizardRouter.steps()) {
        if (step === to) {
          break;
        }
        if (!SetupWizardService.validateRoute(step).valid) {
          store.setCurrentSetupStep(step);
          next({ name: step });
          return true;
        }
      }
    }
    store.setCurrentSetupStep(to);
    next();
    return true;
  }

  static validateRoutes(name: string = SetupWizardService.currStep.name): boolean {
    for (const step of setupWizardRouter.steps()) {
      if (!SetupWizardService.validateRoute(step).valid) return false;
      if (step === name) break;
    }
    return true;
  }

  static validateRoute(name: string): ValidationResult {
    const context = ruleContext();
    const rules = SetupWizardService.stepRules(name);
    return validate(context, rules);
  }

  static async initStores() {}

  static async resetStores(includingMasterDataStore?: boolean) {
    const setupWizardStore = useSetupWizardStore();
    const newProjectsStore = useNewProjectsStore();
    const messageStore = useMessageStore();
    newProjectsStore.$reset();
    setupWizardStore.$reset();
    messageStore.$reset();
    if (includingMasterDataStore) {
      const masterDataStore = useMasterDataStore();
      masterDataStore.$reset();
      await masterDataStore.bootstrap();
    }
  }

  private static findPrevMainStep(): string | undefined {
    const store = useSetupWizardStore();
    if (!store.currentSetupStep) {
      return undefined;
    }
    for (const step of setupWizardRouter.steps(store.currentSetupStep, true)) {
      if (SetupWizardService.mainSetupSteps.includes(step)) return step;
    }
  }

  private static stepRules(name: string): Rules {
    switch (name) {
      case SETUP_REPOSITORY:
        return repositoryRules;
      case SETUP_FRAMEWORK:
        return frameworkRules;
      case SETUP_BUILD:
        return buildRules;
      default:
        return {};
    }
  }
}

function emptyLoader(timeout: number = 1000): StepLoaderPromise {
  return () => useSetupWizardStore().loadingGuard(() => sleep(timeout));
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function failingLoader(timeout: number = 1000, reason: string = 'failed test loader'): StepLoaderPromise {
  return async (context: NavigationContext) => {
    await emptyLoader(timeout)(context);
    context.cancel(reason);
  };
}

async function detectWorkflow(context: NavigationContext) {
  const setupWizardStore = useSetupWizardStore();
  setupWizardStore.setNewProject();
  setupWizardStore.resetUnusedRepositoryPanel();
  await setupWizardStore.startDetection().catch((error) => {
    useMessageStore().addError(error.message);
    context.cancel(error);
  });
}

async function setBuildsteps(context: NavigationContext) {
  const setupStore = useSetupWizardStore();
  const newProjectsStore = useNewProjectsStore();
  const detection = setupStore.selectedDetectionResult;

  if (detection) {
    newProjectsStore.setBuildSteps(detection.buildSteps.map(apiBuildStepToBuildStep));
    detection.runtimeStep && newProjectsStore.setRuntimeStep(apiRuntimeStepToRuntimeStep(detection.runtimeStep));
    newProjectsStore.deployStep = apiDeployStepToDeployStepInput(detection.deployStep);
    newProjectsStore.databaseEnabled = _.includes(detection.requiredFeatures, Feature.DB.toString());
    newProjectsStore.mailAccountEnabled = _.includes(detection.requiredFeatures, Feature.MAIL.toString());
    if (setupStore.selectedProjectType) {
      newProjectsStore.projectType = setupStore.selectedProjectType;
    }
  } else {
    context.cancel('no detection result selected');
  }
}

export async function createProject() {
  const setupWizardStore = useSetupWizardStore();
  await setupWizardStore
    .createProject()
    .then((data) => {
      router.push({ name: 'detail', params: { projectId: data.id } });
    })
    .catch((err) => {
      useMessageStore().addError(err.message);
    });
}

const routeLookup: LookupTable = {
  [SETUP_REPOSITORY]: SETUP_FRAMEWORK,
  [SETUP_FRAMEWORK]: SETUP_BUILD,
  [SETUP_BUILD]: SETUP_RUNTIME,
  [SETUP_RUNTIME]: SETUP_DEPLOYMENT,
  [SETUP_DEPLOYMENT]: SETUP_SUMMARY,
};

const setupWizardRouter = new SetupWizardRouter(routeLookup);
setupWizardRouter.addNextLoader(SETUP_REPOSITORY, detectWorkflow);
setupWizardRouter.addNextLoader(SETUP_FRAMEWORK, setBuildsteps);
setupWizardRouter.addNextLoader(SETUP_BUILD, {
  if: ({ setupWizard }) => setupWizard.selectedProjectTypeGroup === ProjectTypeGroup.dynamic,
});
setupWizardRouter.addNextLoader(SETUP_RUNTIME, {
  if: ({ setupWizard }) => setupWizard.selectedProjectTypeGroup === ProjectTypeGroup.dynamic,
});
