import { authInstance } from '@/plugins/axios';
import crypto from 'crypto';
import * as _ from 'lodash';
import { EnvVars } from '@/utils/env-vars';
import router from '@/router/router';
import { getOwnSecondAndFirstLevelDomain, popPersistedActualLocation } from '@/utils';
import { RawLocation } from 'vue-router';
import { mapBrowserLanguageToMarket } from '@/utils';
import { useAuthStore } from '@/stores/auth';
import CriticalErrorService from '@/services/critical-error-service';

interface OauthInitRequestParams {
  client_id: string;
  redirect_uri: string;
  response_type: string;
  state: string;
  code_challenge: string;
  code_challenge_method: string;
  ui_locales: string;
  prompt: 'none' | 'login' | 'elogin' | null;
  market?: 2 | 6 | 7 | 11 | 16 | 18 | 21 | 22;
}

interface OAuthAuthCodeResponse {
  code: string;
  state: string;
  error: string;
}

interface OAuthTokenResponse {
  expires_in: number;
  actor_type: string;
  actor_id: string;
  id_token: string;
}

export interface Actor {
  id: string;
  type?: string;
}

const OAUTH_CLIENT_ID = EnvVars.getOauthClientId();
const OAUTH_REDIRECT_URI = `${window.location.origin}/auth`;
const OAUTH_INIT_RESPONSE_TYPE = 'code';
const OAUTH_CODE_CHALLENGE_METHOD = 'S256';
const OAUTH_GRANT_TYPE = 'authorization_code';
const STORAGE_KEY_CODE_VERIFIER = 'ionosspaceCodeVerifier';
const STORAGE_KEY_STATE = 'ionosspaceState';
export const LOCAL_STORAGE_KEY_MARKET = 'IONOS_SPACE_MARKET';
export const SESSION_STORAGE_KEY_TRYMARKETS = 'IONOS_SPACE_TRYMARKETS';

export default class AuthService {
  static goToLogin(prompt: 'none' | 'login' | 'elogin' | null) {
    const code_verifier: string = AuthService.createCodeVerifier();
    sessionStorage.setItem(STORAGE_KEY_CODE_VERIFIER, code_verifier);
    const code_challenge: string = AuthService.createCodeChallenge(code_verifier);

    const state: string = AuthService.createState();
    sessionStorage.setItem(STORAGE_KEY_STATE, state);

    const authInitRequestParams: OauthInitRequestParams = {
      client_id: OAUTH_CLIENT_ID,
      redirect_uri: OAUTH_REDIRECT_URI,
      response_type: OAUTH_INIT_RESPONSE_TYPE,
      state,
      code_challenge,
      code_challenge_method: OAUTH_CODE_CHALLENGE_METHOD,
      ui_locales: 'en-US',
      prompt: prompt,
    };

    let marketString = null as string | null;
    if (localStorage.getItem(LOCAL_STORAGE_KEY_MARKET)) {
      marketString = localStorage.getItem(LOCAL_STORAGE_KEY_MARKET);
    } else {
      if ('login' === prompt) {
        marketString = mapBrowserLanguageToMarket();
      } else if ('none' === prompt) {
        if (this.hasMarketsToTry()) {
          marketString = this.shiftMarketToTry();
        } else if (null === sessionStorage.getItem(SESSION_STORAGE_KEY_TRYMARKETS)) {
          //we don't know the market, start guessing and writing the try order to session storage
          this.pushMarketToTry(mapBrowserLanguageToMarket());
          EnvVars.getActiveMarkets().forEach((value: string) => {
            this.pushMarketToTry(value);
          });
          marketString = this.shiftMarketToTry();
        }
      }
    }

    if (marketString) {
      const marketNumber = this.mapMarketAbbreviationToNumber(marketString);
      if (marketNumber) {
        //@ts-ignore
        authInitRequestParams.market = marketNumber;
      }
    }

    location.href = `${EnvVars.getOauthUrl()}/auth/init?${AuthService.objectToURLQueryString(authInitRequestParams)}`;
  }

  static handleAuthentication({ state, code, error }: OAuthAuthCodeResponse) {
    const initialState: string = <string>sessionStorage.getItem(STORAGE_KEY_STATE);
    if (AuthService.validateState(initialState, state)) {
      if (code) {
        AuthService.handleAuthCode(code);
      } else if (error) {
        if (this.hasMarketsToTry()) {
          AuthService.goToLogin('none');
        } else {
          router.push('sign-up');
        }
      }
    } else {
      console.error('Error handling auth code');
    }
  }

  static handleAuthCode(code: string) {
    const params: URLSearchParams = new URLSearchParams();
    params.append('grant_type', OAUTH_GRANT_TYPE);
    params.append('code', code);
    params.append('redirect_uri', OAUTH_REDIRECT_URI);
    params.append('code_verifier', <string>sessionStorage.getItem(STORAGE_KEY_CODE_VERIFIER));
    params.append('client_id', OAUTH_CLIENT_ID);

    authInstance
      .post('/token', params)
      .then((response) => {
        AuthService.handleTokens(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  static handleTokens(tokenResponse: OAuthTokenResponse) {
    const authStore = useAuthStore();

    // @ts-ignore
    const accessToken: string = _.get(tokenResponse, 'access_token');
    const accessTokenExp = _.get(tokenResponse, 'expires_in') * 1000 + Date.now();
    const idToken = _.get(tokenResponse, 'id_token');
    const actorType = tokenResponse?.actor_type;
    const actorId = tokenResponse?.actor_id;

    authStore.login({
      accessToken,
      accessTokenExp,
      idToken,
      actor: actorId ? { id: actorId, type: actorType } : undefined,
    });
    document.cookie = `ionosAnalyzeToken=bearer=${accessToken};path=/;domain=${getOwnSecondAndFirstLevelDomain()}`;
    return new Promise<void>((resolve) => {
      if (!authStore.market) {
        CriticalErrorService.goToError();
      }
      localStorage.setItem(LOCAL_STORAGE_KEY_MARKET, authStore.market as string);
      sessionStorage.removeItem(SESSION_STORAGE_KEY_TRYMARKETS);
      resolve();
    })
      .then(() => {
        const initialPath = popPersistedActualLocation();
        router.push(initialPath ? (initialPath as RawLocation) : '/');
      })
      .catch((error) => {
        console.log(error);
      });
  }

  static revokeAccessToken(token: string) {
    const params: URLSearchParams = new URLSearchParams();
    params.append('client_id', OAUTH_CLIENT_ID);
    params.append('token', token);

    authInstance.post('/token', params).catch((error) => {
      console.error(error);
    });
  }

  static createCodeVerifier(): string {
    return AuthService.createUniqueKey();
  }

  static createCodeChallenge(code_verifier: string): string {
    return AuthService.base64URLEncode(AuthService.sha256(code_verifier));
  }

  static createState(): string {
    return AuthService.createUniqueKey();
  }

  static createUniqueKey(): string {
    return AuthService.base64URLEncode(crypto.randomBytes(32));
  }

  static validateState(initialState: string, responseState: string): boolean {
    return !!initialState && initialState === responseState;
  }

  static base64URLEncode(buffer: Buffer): string {
    return buffer
      .toString('base64') // Regular base64 encoder
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, ''); // Remove any '='s
  }

  static sha256(buffer: string): Buffer {
    return crypto.createHash('sha256').update(buffer).digest();
  }

  static objectToURLQueryString(object: Object): string {
    return Object.entries(object)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');
  }

  static hasMarketsToTry(): boolean {
    const marketsToTry = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY_TRYMARKETS) as string);
    if (marketsToTry) {
      return marketsToTry.length > 0;
    }
    return false;
  }

  static pushMarketToTry(marketAbbreviation: string): void {
    let marketsToTry = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY_TRYMARKETS) as string) as string[];
    if (!marketsToTry) {
      marketsToTry = [] as string[];
    }
    if (-1 === marketsToTry.indexOf(marketAbbreviation)) {
      marketsToTry.push(marketAbbreviation);
    }
    sessionStorage.setItem(SESSION_STORAGE_KEY_TRYMARKETS, JSON.stringify(marketsToTry));
  }

  static shiftMarketToTry(): string | null {
    const marketsToTry = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY_TRYMARKETS) as string);
    if (marketsToTry) {
      const firstMarketToTry = marketsToTry.shift();
      sessionStorage.setItem(SESSION_STORAGE_KEY_TRYMARKETS, JSON.stringify(marketsToTry));
      return firstMarketToTry;
    }
    return null;
  }

  static mapMarketAbbreviationToNumber(marketAbbreviation: string): number | undefined {
    switch (marketAbbreviation) {
      case 'DE':
        return 2;
      case 'UK':
      case 'GB':
        return 6;
      case 'FR':
        return 7;
      case 'US':
        return 11;
      case 'ES':
        return 16;
      case 'CA':
        return 18;
      case 'IT':
        return 21;
      case 'MX':
        return 22;
    }
  }
}
