import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { createI18n, castToVueI18n } from 'vue-i18n-bridge';
import DE from '@/assets/i18n/DE.json';
import GB from '@/assets/i18n/GB.json';
import US from '@/assets/i18n/US.json';
import CA from '@/assets/i18n/CA.json';
import ES from '@/assets/i18n/ES.json';
import MX from '@/assets/i18n/MX.json';
import FR from '@/assets/i18n/FR.json';
import IT from '@/assets/i18n/IT.json';
import en from 'vee-validate/dist/locale/en.json';
import { merge } from 'lodash';
import { mapBrowserLanguageToMarket } from '@/utils';

Vue.use(VueI18n, { bridge: true, warnHtmlInMessage: 'off' });

const locale = mapBrowserLanguageToMarket();
const i18n = castToVueI18n(
  createI18n(
    {
      legacy: false,
      warnHtmlMessage: false,
      locale,
      messages: {
        US: { ...US, ...en.messages },
        DE: { ...merge({}, US, DE), ...en.messages },
        GB: { ...merge({}, US, GB), ...en.messages },
        CA: { ...merge({}, US, CA), ...en.messages },
        ES: { ...merge({}, US, ES), ...en.messages },
        MX: { ...merge({}, US, MX), ...en.messages },
        FR: { ...merge({}, US, FR), ...en.messages },
        IT: { ...merge({}, US, IT), ...en.messages },
      },
    },
    VueI18n
  )
);

Vue.use(i18n);

export default i18n;
