













import { githubApiClient } from '@/plugins/axios';
import { GithubRepository } from 'ionos-space-api-v4';
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api';
import { getRepositoryNameWithoutRepoOwner } from '@/utils/projects';
import { ACCOUNT_ME } from '@/utils/const';
import { useGitHubStore } from '@/stores/github';

export default defineComponent({
  name: 'AppRepositorySelection',
  props: {
    label: String,
    placeholder: String,
    clearable: Boolean,
  },
  setup() {
    const githubStore = useGitHubStore();
    const githubRepositories = ref<GithubRepository[]>([]);

    const load = async () => {
      if (!githubStore.installation?.id) return;
      await githubApiClient
        .getRepositories(ACCOUNT_ME, githubStore.installation?.id, {
          pageSize: 1000,
        })
        .then(({ data }: any) => {
          githubRepositories.value = data.values.sort((a: GithubRepository, b: GithubRepository) => {
            return b.inUse && a.inUse ? 0 : b.inUse && !a.inUse ? 1 : -1;
          });
        });
    };

    const repositoriesForSelection = computed<GithubRepository[]>(() => {
      return githubRepositories.value
        .map((value: GithubRepository) => {
          const repositoryNameWithoutRepoOwner = getRepositoryNameWithoutRepoOwner(value.fullName);
          return {
            text: value.inUse ? repositoryNameWithoutRepoOwner : '',
            ...value,
          };
        })
        .filter((repo) => {
          return repo.text !== '';
        });
    });

    onMounted(async () => {
      await load();
    });

    return {
      repositoriesForSelection,
    };
  },
});
