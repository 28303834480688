import { extend } from 'vee-validate';
import { githubApiClient } from '@/plugins/axios';
import i18n from '@/plugins/i18n';
import { githubUrlToGithubSourceRepository } from '@/model/storeApiConverter';
import { ACCOUNT_ME } from '@/utils/const';
import { useGitHubStore } from '@/stores/github';

extend('existingGithubRepository', {
  validate: async (value) => {
    if (typeof value === 'string') {
      try {
        const gitHubStore = useGitHubStore();
        const input = githubUrlToGithubSourceRepository(value);
        if (input && gitHubStore.internalInstallationId) {
          await githubApiClient.existsGithubRepository(
            ACCOUNT_ME,
            gitHubStore.internalInstallationId,
            input.owner,
            input.repo
          );
          return true;
        }
      } catch (err: any) {
        if (err.response.status === 404) {
          return false;
        }
      }
    }
    return false;
  },
  message: i18n.t('setup.forms.errors.githubRepoNotExisting').toString(),
});
