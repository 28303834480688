






import { defineComponent } from '@vue/composition-api';
import { useAuthStore } from '@/stores/auth';
import AuthService from '@/services/auth-service';
import router from '@/router/router';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'Auth',
  setup(props, { root: { $route } }) {
    const { loggedIn } = storeToRefs(useAuthStore());

    if (loggedIn.value()) {
      router.push('/');
    } else {
      const code: string = $route.query.code as string;
      const state: string = $route.query.state as string;
      const error: string = $route.query.error as string;
      AuthService.handleAuthentication({ code, state, error });
    }
  },
});
