import { defineStore } from 'pinia';
import { projectApiClient, deploymentApiClient } from '@/plugins/axios';
import { Project, Branch, ProjectSortField, ProjectDeploymentToken, Deployment } from '@/model/store';
import {
  directorySessionTokenToStoreSessionToken,
  phpMyAdminSessionTokenToStoreSessionToken,
} from '@/model/storeApiConverter';
import { ACCOUNT_ME, PINIA_PERSIST_KEY } from '@/utils/const';
import ProjectsApiService from '@/services/projects-api-service';
import { useAccountInfoStore } from '@/stores/account-info';
import { urlWithHttpsProtocol } from '@/utils';

export interface ProjectsState {
  projectListLoaded: boolean;
  projects: Project[];
  selectedProjectId?: string;
  directorySessionTokens: ProjectDeploymentToken[];
  sort: string;
  phpMyAdminTokens: ProjectDeploymentToken[];
  deploymentViewerSettings: {
    projectId: string | undefined;
    branchId: string | undefined;
    deploymentId: string | undefined;
  };
}

const defaultSort = ProjectSortField.lastDeploymentDateDesc;
const matchesProjectDeployment = (
  item: ProjectDeploymentToken,
  projectId: string,
  branchId: string,
  deploymentId: string
): boolean => {
  return item.projectId === projectId && item.branchId === branchId && item.deploymentId === deploymentId;
};

export const useProjectsStore = defineStore('projects', {
  state: (): ProjectsState => ({
    projectListLoaded: false,
    projects: [],
    selectedProjectId: undefined,
    directorySessionTokens: [],
    sort: defaultSort,
    phpMyAdminTokens: [],
    deploymentViewerSettings: {
      projectId: undefined,
      branchId: undefined,
      deploymentId: undefined,
    },
  }),
  getters: {
    selectedProject({ projectById }: any): Project | undefined {
      if (this.selectedProjectId) {
        return projectById(this.selectedProjectId);
      }
      return undefined;
    },
    projectByName() {
      return (name: string): Project | undefined => {
        return this.projects.find((project: Project) => project.name === name);
      };
    },
    projectById() {
      return (id: string): Project | undefined => {
        return this.projects.find((project: Project) => project.id === id);
      };
    },
    branchById({ allBranchesOfProject }) {
      return (projectId: string, branchId: string): Branch | undefined => {
        const allBranches = allBranchesOfProject(projectId);
        if (allBranches) {
          return allBranches.find((branch: Branch) => branch.id === branchId);
        }
        return undefined;
      };
    },
    deploymentById({ branchById }) {
      return (projectId: string, branchId: string, deploymentId: string): Deployment | undefined => {
        const branch = branchById(projectId, branchId);
        if (branch) {
          return branch.deployments.find((deployment: Deployment) => deployment.id === deploymentId);
        }
        return undefined;
      };
    },
    allBranchesOfProject({ projectById }) {
      return (projectId: string): Branch[] | undefined => {
        const project = projectById(projectId);
        if (project) {
          const allBranches: Branch[] = [];
          if (project.productionBranch) {
            allBranches.push(project.productionBranch);
          }
          return allBranches.concat(project.branches);
        }
        return undefined;
      };
    },
    allDeploymentsOfProject({ allBranchesOfProject }) {
      return (projectId: string): Deployment[] | undefined => {
        const branches = allBranchesOfProject(projectId);
        if (branches) {
          const deployments: Deployment[] = [];
          branches.forEach((branch: Branch) => {
            branch.deployments.forEach((deployment: Deployment) => deployments.push(deployment));
          });
          return deployments;
        }
        return undefined;
      };
    },
    projectGithubUrl({ selectedProject }) {
      return (project: Project | undefined = selectedProject, branch: Branch | undefined): string => {
        if (project) {
          const foundBranch = branch || project.productionBranch;
          return `https://github.com/${project.gitRepository?.fullName}${
            foundBranch?.name ? '/tree/' + foundBranch.name : ''
          }`;
        }
        return '';
      };
    },
    projectBuildLogUrl({ selectedProject }) {
      return (project: Project | undefined = selectedProject, branch: Branch | undefined): string => {
        if (project) {
          const foundBranch = branch || project.productionBranch;
          return foundBranch?.buildState?.url ?? '';
        }
        return '';
      };
    },
    deploymentViewerLink() {
      return (projectId: string, branchId: string, deploymentId: string, path: string = ''): object | undefined => {
        const foundDeployment = this.deploymentById(projectId, branchId, deploymentId);
        if (foundDeployment) {
          return {
            name: 'project-files',
            params: {
              projectId,
              branchId,
              deploymentId,
              path,
            },
          };
        }
        return undefined;
      };
    },
    directorySessionToken() {
      return (): ProjectDeploymentToken | undefined => {
        const { projectId, branchId, deploymentId } = this.deploymentViewerSettings;
        return this.directorySessionTokenByProjectDeployment(projectId, branchId, deploymentId);
      };
    },
    directorySessionTokenByProjectDeployment() {
      return (projectId: string, branchId: string, deploymentId: string): ProjectDeploymentToken | undefined => {
        return this.directorySessionTokens.find(
          (item) => matchesProjectDeployment(item, projectId, branchId, deploymentId) && item.expires > Date.now()
        );
      };
    },
    phpMyAdminToken({ phpMyAdminTokenByProjectDeployment }) {
      return (projectId: string, branchId: string, deploymentId: string): ProjectDeploymentToken | undefined => {
        return phpMyAdminTokenByProjectDeployment(projectId, branchId, deploymentId);
      };
    },
    phpMyAdminTokenByProjectDeployment() {
      return (projectId: string, branchId: string, deploymentId: string): ProjectDeploymentToken | undefined => {
        return this.phpMyAdminTokens.find(
          (item) => matchesProjectDeployment(item, projectId, branchId, deploymentId) && item.expires > Date.now()
        );
      };
    },
  },
  actions: {
    async loadListOfProjects(forceLoad?: boolean): Promise<void> {
      if (!this.projectListLoaded || forceLoad) {
        const projects = await ProjectsApiService.loadListOfProjects(this.sort);
        if (projects) {
          const newProjectList: Project[] = [];
          projects.forEach((project: Project) => {
            const existingProject = this.projectById(project.id);
            newProjectList.push(existingProject ?? project);
          });
          this.projects = [...newProjectList];

          await this.loadProductionBranches();
        }
        this.projectListLoaded = true;
      }
    },
    async loadProductionBranches(): Promise<void> {
      const calls: any[] = [];

      this.projects.forEach((project: Project) => {
        if (!project.productionBranch) {
          calls.push(this.loadBranch(project.id, project.productionBranchId, { preventStoreUpdate: true }));
        }
      });

      await Promise.allSettled(calls);
      return;
    },
    async loadProject(projectId: string): Promise<undefined | Project> {
      const project = await ProjectsApiService.loadProject(projectId);
      if (project) {
        await this.replaceProject(projectId, project);
        return project;
      }
      return undefined;
    },
    async loadListOfBranchesForProject(projectId: string, forceLoad?: boolean): Promise<Branch[] | undefined> {
      const project = this.projectById(projectId);
      if (!project?._branchesLoaded || forceLoad) {
        const branches = await ProjectsApiService.loadListOfBranches(projectId);
        if (project && branches) {
          const branchesWithExistingDeployments = branches.map((branch) => {
            const existingBranch = this.branchById(projectId, branch.id);
            if (existingBranch?._deploymentsLoaded) {
              branch._deploymentsLoaded = true;
              branch.deployments = existingBranch.deployments;
            }
            return branch;
          });

          project.branches = [];
          branchesWithExistingDeployments.forEach((branch) => this.replaceBranch(projectId, branch.id, branch));
          project._branchesLoaded = true;
          project._productionBranchLoaded = true;

          return branches;
        }
      }
      return undefined;
    },
    async loadBranch(projectId: string, branchId: string): Promise<undefined | Branch> {
      let project = this.projectById(projectId);
      if (!project) {
        await this.loadListOfProjects(true);
        project = this.projectById(projectId);
      }
      if (project) {
        const branch = await ProjectsApiService.loadBranch(projectId, branchId);
        if (branch) {
          await this.replaceBranch(projectId, branchId, branch);
          return branch;
        }
      }
      return undefined;
    },
    async loadDeployment(
      projectId: string,
      branchId: string,
      deploymentId: string,
      options: {
        force: boolean;
      } = {
        force: false,
      }
    ): Promise<undefined | Deployment> {
      const branch = this.branchById(projectId, branchId);
      if (!branch) {
        await this.loadBranch(projectId, branchId);
      }
      const deployment = this.deploymentById(projectId, branchId, deploymentId);
      if (!deployment || !deployment._detailsLoaded || options.force) {
        const deployment = await ProjectsApiService.loadDeployment(projectId, branchId, deploymentId);
        await this.replaceDeployment(projectId, branchId, deploymentId, deployment);
        return deployment;
      } else if (deployment) {
        return deployment;
      }
      return undefined;
    },
    async loadListOfDeploymentsForBranch(projectId: string, branchId: string): Promise<void> {
      const branch = this.branchById(projectId, branchId);
      if (branch) {
        const deployments = await ProjectsApiService.loadListOfDeployments(projectId, branchId);

        if (deployments) {
          const deploymentsWithLoadedDetails = deployments.map((deployment) => {
            const existingDeployment = this.deploymentById(projectId, branchId, deployment.id);
            return existingDeployment ?? deployment;
          });

          branch.deployments = [];
          deploymentsWithLoadedDetails.forEach((deployment) =>
            this.replaceDeployment(projectId, branchId, deployment.id, deployment)
          );
          branch._deploymentsLoaded = true;
        }
      }
    },
    async addProject(projectId: string, project: Project): Promise<void> {
      await this.replaceProject(projectId, project);
    },
    async replaceProject(projectId: string, project?: Project): Promise<void> {
      const arrayIndexOfProduct = this.projects.findIndex((value: Project) => value.id === projectId);

      if (project) {
        const existingProject = this.projectById(projectId);
        if (existingProject) {
          if (!project.branches.length) {
            project.branches = existingProject.branches;
            project._branchesLoaded = existingProject._branchesLoaded;
          }
          if (!project._freshlyCreated) {
            project._freshlyCreated = existingProject._freshlyCreated;
          }
          if (!project.productionBranch) {
            project.productionBranch = existingProject.productionBranch;
            project._productionBranchLoaded = existingProject._productionBranchLoaded;
          }
          if (existingProject._projectDomain) {
            if (
              (project.domains && project.domains.indexOf(existingProject._projectDomain) >= 0) ||
              (project.siteUrls && project.siteUrls.indexOf(urlWithHttpsProtocol(existingProject._projectDomain)) >= 0)
            ) {
              project._projectDomain = existingProject._projectDomain;
            }
          }
        }
      }

      if (arrayIndexOfProduct === -1) {
        if (project) {
          this.projects.push(project);
        }
      } else {
        if (!project) {
          this.projects.splice(arrayIndexOfProduct, 1);
        } else {
          this.projects.splice(arrayIndexOfProduct, 1, project);
        }
      }
    },
    async deleteProject(projectId: string, deleteRepository: boolean = false): Promise<void> {
      await projectApiClient
        .deleteProject(ACCOUNT_ME, projectId, { deleteRepository })
        .catch((error) => console.log(error));
      const project = this.projectById(projectId);
      if (project) {
        const projectType = project.projectType;
        await this.removeProject(projectId);
        useAccountInfoStore().subtractOneProjectType(projectType);
      }
    },
    async removeProject(projectId: string) {
      await this.replaceProject(projectId);
    },
    async replaceBranch(projectId: string, branchId: string, branch?: Branch): Promise<void> {
      const project = this.projectById(projectId);

      if (branch) {
        const existingBranch = this.branchById(projectId, branchId);
        if (existingBranch && !branch._deploymentsLoaded) {
          branch._deploymentsLoaded = existingBranch._deploymentsLoaded;
          branch.deployments = existingBranch.deployments;
        }
      }

      if (project) {
        if (project.productionBranchId === branchId) {
          project.productionBranch = branch;
          if (branch) {
            project._productionBranchLoaded = true;
          }
        } else {
          const arrayIndexOfBranch = project.branches.findIndex((value: Branch) => value.id === branchId);
          if (arrayIndexOfBranch === -1) {
            if (branch) {
              project.branches.push(branch);
            }
          } else {
            if (!branch) {
              project.branches.splice(arrayIndexOfBranch, 1);
            } else {
              project.branches.splice(arrayIndexOfBranch, 1, branch);
            }
          }
        }
      }
    },
    async addDeployment(
      projectId: string,
      branchId: string,
      deploymentId: string,
      deployment: Deployment
    ): Promise<void> {
      await this.replaceDeployment(projectId, branchId, deploymentId, deployment);
    },
    async replaceDeployment(
      projectId: string,
      branchId: string,
      deploymentId: string,
      deployment?: Deployment
    ): Promise<void> {
      const branch = this.branchById(projectId, branchId);

      if (deployment) {
        const existingDeployment = this.deploymentById(projectId, branchId, deploymentId);
        if (existingDeployment && !deployment._detailsLoaded) {
          deployment._detailsLoaded = existingDeployment._detailsLoaded;
          deployment.webspace = existingDeployment.webspace;
          deployment.database = existingDeployment.database;
        }
      }

      if (branch) {
        const arrayIndexOfDeployment = branch.deployments.findIndex((value: Deployment) => value.id === deploymentId);
        if (arrayIndexOfDeployment === -1) {
          if (deployment) {
            branch.deployments.push(deployment);
          }
        } else {
          if (!deployment) {
            branch.deployments.splice(arrayIndexOfDeployment, 1);
          } else {
            branch.deployments.splice(arrayIndexOfDeployment, 1, deployment);
          }
        }
      }
    },
    removeBranch(projectId: string, branchId: string) {
      this.replaceBranch(projectId, branchId);
    },
    async removeDeployment(projectId: string, branchId: string, deploymentId: string) {
      await this.replaceDeployment(projectId, branchId, deploymentId);
      const project = this.projectById(projectId);
      if (project) {
        if (branchId === project.productionBranchId) {
          project.productionDeploymentCount--;
        } else {
          project.stagingDeploymentCount--;
        }
      }
    },
    setDirectorySessionToken(session: ProjectDeploymentToken) {
      const { projectId, branchId, deploymentId, token, expires } = session;
      if (projectId && branchId && deploymentId && token && expires && new Date(expires).getTime() > Date.now()) {
        const index = this.directorySessionTokens.findIndex((token) =>
          matchesProjectDeployment(token, projectId, branchId, deploymentId)
        );
        if (index > -1) {
          this.directorySessionTokens.splice(index, 1, session);
        } else {
          this.directorySessionTokens.push(session);
        }
      }
    },
    setPhpMyAdminToken(session: ProjectDeploymentToken) {
      const { projectId, branchId, deploymentId, token, expires } = session;
      if (projectId && branchId && deploymentId && token && expires && new Date(expires).getTime() > Date.now()) {
        const index = this.phpMyAdminTokens.findIndex((token) =>
          matchesProjectDeployment(token, projectId, branchId, deploymentId)
        );
        if (index > -1) {
          this.phpMyAdminTokens.splice(index, 1, session);
        } else {
          this.phpMyAdminTokens.push(session);
        }
      }
    },
    async sortProjects(sort: ProjectSortField) {
      this.sort = sort;
      await this.loadListOfProjects(true);
    },
    async selectProject(projectId: string) {
      let project = this.projectById(projectId);
      if (!project) {
        if (this.projectListLoaded) {
          this.projectListLoaded = false;
          this.projects = [];
        }
        await this.loadProject(projectId);
        project = this.projectById(projectId);
      }
      if (project) {
        let possibleParallelCalls: any[] = [];
        if (!project._detailsLoaded) {
          possibleParallelCalls.push(this.loadProject(projectId));
        }
        if (!project._branchesLoaded) {
          possibleParallelCalls.push(this.loadListOfBranchesForProject(projectId));
        } else if (!project._productionBranchLoaded) {
          possibleParallelCalls.push(this.loadBranch(projectId, project.productionBranchId));
        }
        await Promise.all(possibleParallelCalls);

        possibleParallelCalls = [];
        this.allBranchesOfProject(projectId)?.forEach((branch) => {
          if (!branch._deploymentsLoaded && branch.deploymentCount > 0) {
            possibleParallelCalls.push(this.loadListOfDeploymentsForBranch(projectId, branch.id).then(() => {}));
          }
        });
        await Promise.all(possibleParallelCalls);

        this.selectedProjectId = projectId;
      }
    },
    async loadDeploymentDetailsForAllDeploymentsOfProject(projectId: string): Promise<void> {
      const possibleParallelCalls: any[] = [];
      this.allDeploymentsOfProject(projectId)?.forEach((deployment: Deployment) => {
        if (!deployment._detailsLoaded) {
          possibleParallelCalls.push(
            this.loadDeployment(projectId, deployment._branchId, deployment.id, { force: true })
          );
        }
      });

      await Promise.allSettled(possibleParallelCalls);
    },
    async refreshDirectorySessionToken() {
      const { projectId, branchId, deploymentId } = this.deploymentViewerSettings;
      const deployment: Deployment | undefined = this.deploymentById(projectId, branchId, deploymentId);
      const spaceId = deployment?.webspace?.webspace.id;
      if (spaceId) {
        const session = await deploymentApiClient
          .createDirectorySession(ACCOUNT_ME, projectId, branchId, deploymentId, spaceId)
          .then(({ data }) => directorySessionTokenToStoreSessionToken(projectId, branchId, deploymentId, data));
        this.setDirectorySessionToken(session);
      }
    },
    async refreshPhpMyAdminToken({ projectId, branchId, deploymentId }) {
      const deployment: Deployment | undefined = this.deploymentById(projectId, branchId, deploymentId);
      const databaseId = deployment?.database?.database.id;
      if (databaseId) {
        const session = await deploymentApiClient
          .createPhpMyAdminSession(ACCOUNT_ME, projectId, branchId, deploymentId, databaseId)
          .then(({ data }) => phpMyAdminSessionTokenToStoreSessionToken(projectId, branchId, deploymentId, data));
        this.setPhpMyAdminToken(session);
      }
    },
    async connectDomain({ projectId, branchId, deploymentId, domain, force }) {
      await deploymentApiClient.connectDomain(ACCOUNT_ME, projectId, branchId, deploymentId, {
        domain,
        force,
      });
    },
    async disconnectDomain({ projectId, branchId, deploymentId }) {
      await deploymentApiClient.disconnectDomain(ACCOUNT_ME, projectId, branchId, deploymentId);
    },
  },
  persist: {
    key: PINIA_PERSIST_KEY + 'v2',
    storage: window.localStorage,
    paths: ['sort'],
  },
});
