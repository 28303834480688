






























import { defineComponent, inject } from '@vue/composition-api';
import { useAccountInfoStore } from '@/stores/account-info';
import AppLogo from '@/components/shared/Logo.vue';
import { storeToRefs } from 'pinia';
import { useGitHubStore } from '@/stores/github';

export default defineComponent({
  name: 'AppNavigation',
  components: { AppLogo },
  props: {
    isDashboard: Boolean,
    isDetails: Boolean,
    isSetup: Boolean,
  },
  setup(props, { root }) {
    const { isFullyConnected, hasNecessaryPermissions } = storeToRefs(useGitHubStore());

    const openTariffUpgradeDialog: Function = inject<Function>('openTariffUpgradeDialog', () => {});

    const handleNewProjectClick = () => {
      if (useAccountInfoStore().hasFreeProjectType) {
        root.$router.push({ name: 'setup' });
      } else {
        openTariffUpgradeDialog({ noFreeProjectAvailableForSetup: true });
      }
    };

    return {
      isFullyConnected,
      hasNecessaryPermissions,
      handleNewProjectClick,
    };
  },
});
