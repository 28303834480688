import {
  ComponentInstance,
  ComponentInternalInstance,
  ComputedRef,
  computed,
  getCurrentInstance,
} from '@vue/composition-api';
import { Framework } from 'vuetify';

export type ComponentWith$Vuetify<T> = ComponentInstance & { $vuetify: T };

export function instanceHook<T>(
  // eslint-disable-next-line no-unused-vars
  getter: (vm: ComponentInternalInstance) => T,
  fallback: () => T = () => ({} as T)
): ComputedRef<T> {
  return computed(() => {
    const vm = getCurrentInstance();
    return vm !== null ? getter(vm) : fallback();
  });
}

export function useVuetify<T = Framework>(): ComputedRef<T> {
  return instanceHook((vm) => (vm.proxy as ComponentWith$Vuetify<T>).$vuetify);
}
