import Vue from 'vue';
import IonosVuetify, { ionosVuetifyTheme } from 'ionos-vuetify';
import Vuetify from 'vuetify/lib';
import 'ionos-vuetify/src/assets/sass/main.scss';
import '@mdi/font/css/materialdesignicons.css';
import '@material-design-icons/font/outlined.css';
import i18n from './i18n';

Vue.use(Vuetify);
Vue.use(IonosVuetify, { i18n });

export default new Vuetify({
  theme: ionosVuetifyTheme,
  icons: {
    iconfont: 'md',
  },
});
