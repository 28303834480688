















































import { computed, defineComponent, ref } from '@vue/composition-api';
import AppRepositorySelection from '@/components/shared/RepositorySelection.vue';
import i18n from '@/plugins/i18n';
import { Project } from '@/model/store';
import { getRepositoryNameWithoutRepoOwner } from '@/utils/projects';
import { FeedbackInput } from 'ionos-space-api-v4';
import { useProjectsStore } from '@/stores/projects';
import { accountApiClient } from '@/plugins/axios';
import { ACCOUNT_ME } from '@/utils/const';

export default defineComponent({
  name: 'AppContactSupportDialog',
  components: { AppRepositorySelection },
  setup() {
    const projectsStore = useProjectsStore();
    const summary = ref<string>('');
    const description = ref<string>('');
    const selectedRepository = ref<string | null>(null);
    const supportUserInvited = ref<boolean>(false);
    const dialogRef = ref(null as any);
    const projects = computed<Project[]>(() => projectsStore.projects);

    const open = () => {
      dialogRef.value && dialogRef.value.open();
    };

    const close = () => {
      dialogRef.value && dialogRef.value.close();
    };

    const createDescription = computed(() => {
      if (selectedRepository.value) {
        return `${description.value} \n
        ${i18n.t('contactSupport.dialog.ownRepositorySelection.autocomplete.label')} https://github.com/${
          selectedRepository.value
        }`;
      }
      return description.value;
    });

    const confirm = () => {
      const data: FeedbackInput = {
        summary: summary.value,
        description: createDescription.value,
        type: 'BUG',
        supportUserInvited: false,
      };

      if (supportUserInvited.value && selectedRepository.value) {
        const project = projects.value.find((project: Project) => {
          const selected = getRepositoryNameWithoutRepoOwner(selectedRepository.value || '');
          return project.name === selected;
        });
        data.supportUserInvited = supportUserInvited.value;
        data.affectedProjectId = project?.id;
      }

      return accountApiClient.createFeedback(ACCOUNT_ME, data);
    };

    const resetFormInputs = () => {
      summary.value = '';
      description.value = '';
      selectedRepository.value = null;
    };

    return {
      summary,
      description,
      selectedRepository,
      dialogRef,
      open,
      close,
      confirm,
      resetFormInputs,
      supportUserInvited,
    };
  },
});
