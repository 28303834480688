

























import { defineComponent, computed, PropType } from '@vue/composition-api';
import { ProjectType } from 'ionos-space-api-v4';
import { isStarterProjectByType } from '@/utils/projects';
import { prettyBytes } from '@/utils';

export default defineComponent({
  name: 'AppTariffUpgradeItem',
  props: {
    projectType: {
      type: Object as PropType<ProjectType>,
      required: true,
    },
    upgradeActionLabel: String,
  },
  emits: ['upgrade-tariff'],
  setup(props) {
    const quota = computed<string>(() => {
      return prettyBytes(props.projectType.diskQuotaPerBranch);
    });
    const quotaDb = computed<string>(() => {
      return prettyBytes(props.projectType.databaseQuotaPerBranch);
    });

    return {
      quota,
      quotaDb,
      isStarterProjectByType,
    };
  },
});
