import { Window } from '@/utils/types/Properties';

const myWindow: Window = window as any;

export class EnvVars {
  static getReleaseVersion(): string {
    return myWindow.IonosSpaceFrontend.RELEASE_VERSION;
  }

  static getActiveMarkets(): string[] {
    if (myWindow.IonosSpaceFrontend.ACTIVE_MARKETS) {
      return myWindow.IonosSpaceFrontend.ACTIVE_MARKETS;
    }
    return Object.keys(myWindow.IonosSpaceFrontend.DATACENTER_PER_MARKETS);
  }

  static getOauthUrl(): string | undefined {
    return myWindow.IonosSpaceFrontend.OAUTH_URL;
  }

  static getOauthClientId(): string {
    return myWindow.IonosSpaceFrontend.OAUTH_CLIENT_ID;
  }

  static getCentralLogoutUrl(market: string): string {
    return myWindow.IonosSpaceFrontend.DATACENTER_PER_MARKETS[market].LOGOUT_URL;
  }

  static getIonosSpaceApiUrl(market: string): string {
    const url =
      myWindow.IonosSpaceFrontend.IONOSSPACE_API_URL[
        myWindow.IonosSpaceFrontend.DATACENTER_PER_MARKETS[market].DATACENTER
      ];
    if (url === undefined) {
      throw new Error(`IonosSpace API URL for market ${market} not found`);
    }
    return url;
  }

  static getIonosSpaceSentryUrl(): string {
    return myWindow.IonosSpaceFrontend.IONOSSPACE_SENTRY_URL;
  }

  static getScreenshotApiUrl(): string {
    return myWindow.IonosSpaceFrontend.SCREENSHOT_API_URL;
  }

  static getDirectoryApiUrl(): string {
    // the directory api is proxied through the (apache httpd / Vue Dev Server) due to CORS
    return '/directory-api';
  }

  static getGithubNewInstallationUrl(githubAuthorizationState: string): string {
    return myWindow.IonosSpaceFrontend.GITHUB.NEW_INSTALLATION_URL + githubAuthorizationState;
  }

  static getGithubManageInstallationUrl(githubAccountId: string): string {
    return myWindow.IonosSpaceFrontend.GITHUB.MANAGE_INSTALLATION_URL + `${githubAccountId}/permissions`;
  }

  static getGithubAuthorizationUrl(): string {
    return (
      myWindow.IonosSpaceFrontend.GITHUB.AUTHORIZE_URL +
      `?client_id=${myWindow.IonosSpaceFrontend.GITHUB.CLIENT_ID}&redirect_url=${myWindow.IonosSpaceFrontend.GITHUB.REDIRECT_URL}`
    );
  }

  static getEnventsource(market: string) {
    return EnvVars.getIonosSpaceApiUrl(market) + myWindow.IonosSpaceFrontend.EVENT_PATH;
  }
}
