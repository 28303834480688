import * as _ from 'lodash';
import { defineStore } from 'pinia';
import axios, { AxiosError } from 'axios';
import {
  DetectionBaseType,
  DetectionInput,
  GithubRepositoryInput,
  ProviderType,
  RepositoryInputUnion,
} from 'ionos-space-api-v4';
import i18n from '@/plugins/i18n';

import { detectionApiClient, metadataApiClient, projectApiClient } from '@/plugins/axios';
import { useNewProjectsStore } from '@/stores/new-project';
import { useMasterDataStore } from '@/stores/master-data';
import { useErrorHandlingStore } from '@/stores/error-handling';
import { cloneDeepWithUUID } from '@/utils';
import { ACCOUNT_ME, SETUP_REPOSITORY } from '@/utils/const';
import {
  BuildStep,
  DetectionResult,
  FrameworkPanel,
  NewProjectState,
  Project,
  ProjectTypeGroup,
  SetupWizardState,
} from '@/model/store';
import {
  apiBuildStepDescriptionToBuildStep,
  apiBuildStepToBuildStep,
  apiDetectionResultToDetectionResult,
  emptyGithubDestinationRepository,
  emptyGithubRepository,
  newProjectStateToApiGithubProjectInput,
  projectToStoreProject,
  stringToEnumValue,
} from '@/model/storeApiConverter';
import { useProjectsStore } from './projects';
import { useGitHubStore } from './github';

export enum RepositoryPanel {
  Own,
  ThirdParty,
}

export const useSetupWizardStore = defineStore('setup-wizard', {
  state: (): SetupWizardState => ({
    createdProject: undefined,
    completedBuildSteps: [],
    detectionResults: [],
    calledSetupSteps: [SETUP_REPOSITORY],
    currentSetupStep: SETUP_REPOSITORY,
    loading: false,
    selectedSample: '',
    selectedRepositoryPanel: RepositoryPanel.Own,
    selectedProjectTypePanel: FrameworkPanel.starter,
    selectedDetectionIndex: 0,
    selectedBuildStepPanel: 0,
    selectedConfigurationPanel: 0,
    selectedDeploymentPanel: 0,
    selectedRuntimePanel: 0,
    ownRepository: emptyGithubRepository(),
    thirdPartyRepository: {
      source: undefined,
      destination: emptyGithubDestinationRepository(),
    },
  }),
  getters: {
    selectedDetectionResult(): DetectionResult | undefined {
      return this.detectionResults?.[this.selectedDetectionIndex];
    },
    selectedProjectType(): string | undefined {
      const masterDataStore = useMasterDataStore();
      const allowedTypes = masterDataStore.getPackageWithRequiredFeatures([]);

      return allowedTypes[this.selectedProjectTypePanel]?.name;
    },
    selectedProjectTypeGroup(): ProjectTypeGroup {
      if (this.selectedProjectType) {
        const masterDataStore = useMasterDataStore();
        const projectType = masterDataStore.projectTypeByName(this.selectedProjectType);
        if (projectType) {
          return stringToEnumValue(ProjectTypeGroup, projectType.group.toUpperCase());
        }
      }
      return ProjectTypeGroup.none;
    },
    isCopyingRepository(): boolean {
      return this.selectedRepositoryPanel === RepositoryPanel.ThirdParty;
    },
  },
  actions: {
    addSetupStep(step: string) {
      if (!this.calledSetupSteps.includes(step)) {
        this.calledSetupSteps.push(step);
      }
    },
    setCurrentSetupStep(step: string) {
      this.currentSetupStep = step;
      this.addSetupStep(step);
    },
    selectRepositoryPanel(value: number) {
      this.selectedRepositoryPanel = value;
    },
    selectProjectTypePanel(value: number) {
      this.selectedProjectTypePanel = value;
    },
    selectDetectionResult(value: number) {
      this.selectedDetectionIndex = value;
    },
    selectBuildStepPanel(value: number) {
      this.selectedBuildStepPanel = value;
    },
    selectConfigurationPanel(value: number) {
      this.selectedConfigurationPanel = value;
    },
    selectDeploymentPanel(value: number) {
      this.selectedDeploymentPanel = value;
    },
    selectRuntimePanel(value: number) {
      this.selectedRuntimePanel = value;
    },
    resetUnusedRepositoryPanel() {
      if (this.isCopyingRepository) {
        this.ownRepository = emptyGithubRepository();
      } else {
        this.thirdPartyRepository.source = undefined;
        this.thirdPartyRepository.destination = emptyGithubDestinationRepository();
        this.selectedSample = '';
      }
    },
    setNewProject() {
      const newProjectsStore = useNewProjectsStore();
      if (this.isCopyingRepository) {
        newProjectsStore.sourceRepository = this.thirdPartyRepository.source;
        newProjectsStore.destinationRepository = this.thirdPartyRepository.destination;
      } else {
        newProjectsStore.sourceRepository = undefined;
        newProjectsStore.destinationRepository = {
          repository: this.ownRepository,
        };
      }
    },
    setDetectionProjectType(index: number, projectType: string) {
      const result = this.detectionResults?.[index];
      if (result) {
        result.selectedProjectType = projectType;
        this.detectionResults?.splice(index, 1, result);
      }
    },
    async getBuildStep(name: string): Promise<BuildStep | undefined> {
      const cachedBuildStep = this.completedBuildSteps.find((step) => step.name === name);
      if (cachedBuildStep) {
        return cloneDeepWithUUID(cachedBuildStep);
      }
      const newProjectsStore = useNewProjectsStore();
      const githubStore = useGitHubStore();

      let detectionInput: RepositoryInputUnion;
      if (newProjectsStore.sourceRepository) {
        detectionInput = {
          type: ProviderType.GITHUB,
          owner: newProjectsStore.sourceRepository.owner,
          repo: newProjectsStore.sourceRepository.repo,
          branch: newProjectsStore.sourceRepository.branch,
        };
      } else {
        detectionInput = {
          type: ProviderType.GITHUB,
          owner: githubStore.getOwner || '',
          repo: newProjectsStore.destinationRepository.repository.repo,
          branch: newProjectsStore.destinationRepository.repository.branch,
        };
      }
      return detectionApiClient
        .completeBuildStep(ACCOUNT_ME, { buildStepName: name, repository: detectionInput })
        .then(({ data }) => {
          const buildStep = apiBuildStepToBuildStep(data);
          this.completedBuildSteps.push(buildStep);
          return cloneDeepWithUUID(buildStep);
        })
        .catch((error: Error | AxiosError) => {
          const errorStore = useErrorHandlingStore();

          if (axios.isAxiosError(error)) {
            if (error.response) {
              errorStore.setError({
                code: error.response.status,
                message: error.response.data.message,
              });
            }
          } else {
            errorStore.setError({
              code: 500,
              message: error.message,
            });
          }
          return metadataApiClient.getBuildStepDescriptions().then(({ data }) => {
            const result = data.find((step) => step.name === name);
            if (result) {
              const buildStep = apiBuildStepDescriptionToBuildStep(result);
              this.completedBuildSteps.push(buildStep);
              return cloneDeepWithUUID(buildStep);
            }
          });
        });
    },
    startDetection(): Promise<DetectionResult[]> {
      const githubStore = useGitHubStore();
      return this.loadingGuard(async () => {
        const newProjectsStore = useNewProjectsStore();
        const repo: RepositoryInputUnion = newProjectsStore.sourceRepository
          ? newProjectsStore.sourceRepository
          : new GithubRepositoryInput({
              type: ProviderType.GITHUB,
              owner: githubStore.getOwner || '',
              repo: newProjectsStore.destinationRepository.repository.repo,
              branch: newProjectsStore.destinationRepository.repository.branch,
            });
        const detectionInput = new DetectionInput({ repository: repo });

        return detectionApiClient.detectWorkflow(ACCOUNT_ME, detectionInput).then(({ data }) => {
          const custom: DetectionResult = {
            supported: { supported: true, compensation: '', reason: '' },
            detectionBase: {
              type: DetectionBaseType.FRAMEWORK,
              framework: { name: i18n.t('setup.frameworkSelection.setupTypes.custom').toString(), id: 'custom' },
            },
            requiredFeatures: [],
            buildSteps: [],
            deployStep: {
              cronJobs: [],
            },
            selectedProjectType: '',
          };
          this.detectionResults = [...data, custom].map(apiDetectionResultToDetectionResult);
          return this.detectionResults;
        });
      });
    },
    createProject(): Promise<Project> {
      return this.loadingGuard(() => {
        const newProjectsStore = useNewProjectsStore();
        const projectsStore = useProjectsStore();
        const githubProjectInput = newProjectStateToApiGithubProjectInput(
          _.omitBy(newProjectsStore.$state, _.isNil) as NewProjectState
        );
        // TODO: dirty fix because of problems on live
        githubProjectInput.overwriteWorkflow = true;
        // TODO: dirty fix, deleting database- and mail feature when detection suggests it, but project type doesn't include it
        if ([ProjectTypeGroup.none, ProjectTypeGroup.static].includes(this.selectedProjectTypeGroup)) {
          githubProjectInput.databaseEnabled = false;
          githubProjectInput.mailAccountEnabled = false;
        }
        return projectApiClient.createProject(ACCOUNT_ME, githubProjectInput).then(async ({ data }) => {
          const project = projectToStoreProject(data);
          project._freshlyCreated = true;
          await projectsStore.addProject(data.id, project);
          await projectsStore.loadListOfProjects(true);
          this.createdProject = project;
          return project;
        });
      });
    },
    loadingGuard<T = unknown>(promise: () => Promise<T>): Promise<T> {
      this.loading = true;
      return promise().finally(() => {
        this.loading = false;
      });
    },
  },
});
