// Make sure that composition api and pinia are imported first
import '@/plugins/composition-api';
import pinia from '@/plugins/pinia';
import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router/router';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';
import '@/plugins/vee-validate';
import '@/styles/main.scss';
import EventSourceService from '@/services/event-source-service';
import { useSentry } from '@/plugins/sentry';

Vue.prototype.$eventSourceService = new EventSourceService();
Vue.config.productionTip = false;

new Vue({
  pinia,
  router,
  vuetify,
  i18n,
  mounted() {
    useSentry();
  },
  render: (h) => h(App),
}).$mount('#app');
