import { defineStore } from 'pinia';
import { AccountInfoState, FreeProjectTypeCount } from '@/model/store';
import { ProjectTypeCount, ProviderType } from 'ionos-space-api-v4';
import { accountInfoApiClient } from '@/plugins/axios';
import { useMasterDataStore } from '@/stores/master-data';
import CriticalErrorService from '@/services/critical-error-service';
import { accountFeedbackTileShownPatch, accountWelcomeShownPatch, patchAccount } from '@/services/patch-service';

export const useAccountInfoStore = defineStore('account-info', {
  state: (): AccountInfoState => ({
    usableAccount: undefined,
    unusableAccounts: undefined,
  }),
  actions: {
    async loadAccountInfo(force: boolean = false): Promise<void> {
      if (!this.usableAccount || force) {
        await accountInfoApiClient
          .getAccountInfo()
          .then(({ data }) => {
            this.usableAccount = data.usableAccount;
            this.unusableAccounts = data.unusableAccounts;
          })
          .catch((error) => {
            if (error?.response?.status) {
              switch (error.response.status) {
                case 401:
                  //account is locked
                  CriticalErrorService.goToError();
                  break;
                case 404:
                  //no account available
                  CriticalErrorService.goToError();
                  break;
              }
            }
            this.usableAccount = undefined;
            this.unusableAccounts = undefined;
          });
      }
    },
    reloadAccountInfo() {
      return this.loadAccountInfo(true).catch((error) => console.log(error));
    },
    async hasAccountInfo(): Promise<boolean> {
      await this.loadAccountInfo();
      return this.usableAccount !== undefined;
    },
    confirmWelcomePage() {
      patchAccount(accountWelcomeShownPatch(new Date().toISOString()))
        .then(({ data }) => {
          if (this.usableAccount) {
            this.usableAccount.showWelcome = data.showWelcome;
          }
        })
        .catch(console.error);
    },
    closeFeedbackTile() {
      patchAccount(accountFeedbackTileShownPatch(new Date().toISOString()))
        .then(({ data }) => {
          if (this.usableAccount) {
            this.usableAccount.showFeedbackTile = data.showFeedbackTile;
          }
        })
        .catch(console.error);
    },
    async upgradeTariff(tariffName: string) {
      const masterDataStore = useMasterDataStore();

      const tariffPath = await masterDataStore.getCustomerShop(tariffName);
      //TODO handle stat if no tariffPath found in store to tarriffName
      if (tariffPath && masterDataStore.upsellInformation) {
        const shopUrl = new URL(tariffPath);
        shopUrl.searchParams.append('contract', masterDataStore.upsellInformation.contractId);
        window.open(shopUrl.toString(), '_blank');
      }
    },
    subtractOneProjectType(projectType: string) {
      if (this.usableAccount) {
        this.usableAccount.projectTypes = this.usableAccount.projectTypes.map(({ name, total, used }) => ({
          name,
          total,
          used: name === projectType ? Math.max(used - 1, 0) : used,
        }));
      }
    },
  },
  getters: {
    showWelcome(state): boolean {
      return state.usableAccount?.showWelcome ?? true;
    },
    showFeedbackTile(state): boolean {
      return state.usableAccount?.showFeedbackTile ?? true;
    },
    hasFreePackage() {
      return (packageName: string, neededPackages: number = 1) => this.freePackages(packageName).free >= neededPackages;
    },
    freePackages(state) {
      return (packageName: string): FreeProjectTypeCount => {
        const usage = state.usableAccount?.projectTypes.find(({ name }) => name === packageName);
        if (usage) {
          return {
            name: usage.name,
            free: usage.total - usage.used,
            total: usage.total,
          };
        }
        return {
          name: packageName,
          free: 0,
          total: 0,
        };
      };
    },
    getProjectCount(state): number {
      const projectTypes = state.usableAccount?.projectTypes || [];
      return projectTypes.reduce((total, currentValue) => total + currentValue.used, 0);
    },
    projectTypesUsage(state): ProjectTypeCount[] | undefined {
      return state.usableAccount?.projectTypes;
    },
    numberOfFreeProjectTypes(state): number {
      const projectTypes = state.usableAccount?.projectTypes || [];
      return projectTypes.reduce(
        (total, projectTypeCount) => (projectTypeCount.total > projectTypeCount.used ? total + 1 : total),
        0
      );
    },
    getProjectTypes(state): ProjectTypeCount[] | undefined {
      return state.usableAccount?.projectTypes;
    },
    getHighestFreeProjectType(state): string | undefined {
      const projectType = state.usableAccount?.projectTypes.find((element) => element.total - element.used > 0);
      return projectType?.name;
    },
    hasFreeProjectType(): boolean {
      return !!this.getHighestFreeProjectType;
    },
    githubInstallationId(state): string | undefined {
      return state.usableAccount?.gitConnections.find((connection) => connection.type === ProviderType.GITHUB)
        ?.installationId;
    },
  },
});
