import {
  Account as ApiAccount,
  AccountPatch as ApiAccountPatch,
  BranchBuildState as ApiBranchBuildState,
  BranchDeploymentState as ApiBranchDeploymentState,
  BuildStep as ApiBuildStep,
  BuildStepDescription as ApiBuildStepDescription,
  BuildStepInput as ApiBuildStepInput,
  DestinationRepositoryInput as ApiDestinationRepositoryInput,
  Database as ApiDatabase,
  DatabaseSettings as ApiDatabaseSettings,
  DeploymentDomain as ApiDeploymentDomain,
  DetailedDeploymentState as ApiDetailedDeploymentState,
  DetectionResult as ApiDetectionResult,
  GithubRepository as ApiGithubRepository,
  Installation as ApiInstallation,
  Patch as ApiPatch,
  ProjectInput as ApiProjectInput,
  ProjectType as ApiProjectType,
  ProviderType as ApiProviderType,
  RepositoryInputUnion as ApiRepositoryInputUnion,
  TemplateFile as ApiTemplateFile,
  UnusableAccount as ApiUnusableAccount,
  UpsellInformation as ApiUpsellInformation,
  VariableInput as ApiVariableInput,
  GitRepository as ApiGitRepository,
  DatabaseState as ApiDatabaseState,
  Webspace as ApiWebspace,
  WebspaceState as ApiWebspaceState,
} from 'ionos-space-api-v4';
import { Actor } from '@/services/auth-service';

export class WelcomeShownPatch implements ApiPatch<string> {
  operation: string;
  payload: string;

  constructor(data: WelcomeShownPatch) {
    this.operation = data.operation;
    this.payload = data.payload;
  }
}

export class FeedbackTileShownPatch implements ApiPatch<string> {
  operation: string;
  payload: string;

  constructor(data: FeedbackTileShownPatch) {
    this.operation = data.operation;
    this.payload = data.payload;
  }
}

export interface AccountPatch extends Omit<ApiAccountPatch, 'welcomeShownPatch' | 'feedbackTileShownPatch'> {
  welcomeShownPatch?: WelcomeShownPatch;
  feedbackTileShownPatch?: FeedbackTileShownPatch;
}

export enum ProjectTypeGroup {
  none = 'NONE',
  static = 'STATIC',
  dynamic = 'DYNAMIC',
}

export enum ProjectType {
  s = 'S',
  l = 'L',
  php = 'phpdb',
}

export interface Project {
  id: string;
  name: string;
  deleted: boolean;
  defaultDomainRootPath: string;
  permissionLost: boolean;
  gitRepository: ApiGitRepository;
  productionBranchId: string;
  productionDeploymentCount: number;
  stagingDeploymentCount: number;
  maxStagingDeploymentCount: number;
  providerType: ApiProviderType;
  projectType: string;
  automaticDeploymentEnabled?: boolean;
  locked: boolean;
  defaultPhpVersion?: string;
  databaseSettings?: ApiDatabaseSettings;
  visitorStatisticsEnabled?: boolean;
  spawningEnabled: boolean;
  domains?: string[];
  siteUrls?: string[];
  lastChangedDate: Date;
  productionBranch?: Branch;
  branches: Branch[];
  _projectDomain: string;
  _detailsLoaded?: boolean;
  _productionBranchLoaded?: boolean;
  _branchesLoaded?: boolean;
  _freshlyCreated?: boolean;
}

export interface Branch {
  id: string;
  name: string;
  productionBranch: boolean;
  webUrl: string;
  workflowPresent: boolean;
  deprecatedWorkflow?: boolean;
  deleted: boolean;
  deploymentCount: number;
  buildState?: BranchBuildState;
  deploymentState?: ApiBranchDeploymentState;
  deployments: Deployment[];
  _deploymentsLoaded?: boolean;
  _projectId: string;
}

export interface Deployment {
  id: string;
  name: string;
  domain?: ApiDeploymentDomain;
  state: ApiDetailedDeploymentState;
  webspace?: DetailedWebspace;
  database?: DetailedDatabase;
  _detailsLoaded?: boolean;
  _projectId: string;
  _branchId: string;
}

export interface BranchBuildState extends Omit<ApiBranchBuildState, 'state'> {
  state?: BranchState;
}

export interface DetailedWebspace {
  state?: ApiWebspaceState;
  webspace: Webspace;
}

export interface Webspace extends Omit<ApiWebspace, 'username'> {
  username?: string;
}

export interface DetailedDatabase {
  state?: ApiDatabaseState;
  database: Database;
}

export interface Database extends Omit<ApiDatabase, 'username'> {
  username?: string;
}

export interface VariableInputs {
  [name: string]: ApiVariableInput;
}

export interface ProjectDeploymentToken {
  projectId: string;
  branchId: string;
  deploymentId: string;
  token: string;
  expires: number;
}

export interface VariableInput extends ApiVariableInput {
  name: string;
  editable?: boolean;
}

export interface BuildStep extends Omit<ApiBuildStep, 'variables'> {
  id: string;
  variables: VariableInput[];
}

export interface TemplateFile extends ApiTemplateFile {
  id: string;
}

export interface RuntimeStep {
  domainRootPath: string;
  templateFiles: TemplateFile[];
  secrets: VariableInput[];
}

export interface DetectionResult extends ApiDetectionResult {
  selectedProjectType: string;
}

export interface BuildStepChanges extends Partial<Omit<ApiBuildStepInput, 'variables'>> {
  id?: string;
  variables?: VariableInput[];
}

export interface NewProjectState extends Omit<ApiProjectInput, 'buildSteps' | 'runtimeStep'> {
  buildSteps: BuildStep[];
  runtimeStep?: RuntimeStep;
}

export interface AccountInfoState {
  usableAccount?: ApiAccount;
  unusableAccounts?: ApiUnusableAccount[];
}

export interface SetupWizardState {
  createdProject?: Project;
  detectionResults?: DetectionResult[];
  completedBuildSteps: BuildStep[];
  calledSetupSteps: string[];
  currentSetupStep: string;
  loading: boolean;
  selectedSample: string;
  selectedRepositoryPanel: number;
  selectedProjectTypePanel: number;
  selectedDetectionIndex: number;
  selectedBuildStepPanel: number;
  selectedConfigurationPanel: number;
  selectedDeploymentPanel: number;
  selectedRuntimePanel: number;
  ownRepository: ApiRepositoryInputUnion;
  thirdPartyRepository: {
    source?: ApiRepositoryInputUnion;
    destination: ApiDestinationRepositoryInput;
  };
}

export interface GitHubState {
  githubRepositories: ApiGithubRepository[];
  repositoryBranches: Array<{
    fullName: string;
    branches: string[];
  }>;
  loaded: boolean;
  installation?: ApiInstallation;
  tokenExists?: boolean;
}

export interface MasterDataState {
  phpVersions: string[];
  buildSteps: ApiBuildStepDescription[];
  projectTypes: ApiProjectType[];
  upsellInformation?: ApiUpsellInformation;
}

export interface ErrorHandlingState {
  error?: {
    code: number;
    message: string;
  };
}

export enum MessageType {
  error = 'error',
  warning = 'warning',
  info = 'info',
}

export enum MessageSource {
  fe = 'FE',
  mw = 'MW',
  github = 'GITHUB',
}

export interface Message {
  id: string;
  type: MessageType;
  source: MessageSource;
  message: string;
}

export interface MessageStoreState {
  messages: Message[];
}

export enum BranchState {
  AWAITING_BUILD = 'AWAITING_BUILD',
  INITIAL_BUILD_FAILED = 'INITIAL_BUILD_FAILED',
  SUCCESS = 'SUCCESS',
  RUNNING = 'RUNNING',
  FAILED = 'FAILED',
  UNKNOWN = 'UNKNOWN',
  ON_GITHUB_DELETE = 'ON_GITHUB_DELETE',
  WORKFLOW_MISSING = 'WORKFLOW_MISSING',
}

export enum ProjectSortField {
  createdDateAsc = 'createdDate:asc',
  createdDateDesc = 'createdDate:desc',
  lastDeploymentDateAsc = 'lastDeploymentDate:asc',
  lastDeploymentDateDesc = 'lastDeploymentDate:desc',
}

export enum DeployStepType {
  Bootstrap = 0,
  Recurring = 1,
}

export enum AccountType {
  USER = 'USER',
}

export interface DeployStepChanges {
  excludes?: string[];
  preDeployment?: string[];
  postDeployment?: string[];
}

export interface FreeProjectTypeCount {
  name: string;
  total: number;
  free: number;
}

export enum FrameworkPanel {
  starter,
  static,
  php,
}

export interface AuthState {
  accessToken: string | null;
  accessTokenExp: number;
  idToken: string | null;
  actor?: Actor;
}

export interface CustomerData {
  id: number;
  gender: string;
  given_name: string;
  family_name: string;
}

export interface UserSessionState {
  frontendToken?: string;
  darkMode?: boolean;
}
