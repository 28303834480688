

















import { defineComponent, computed } from '@vue/composition-api';
import { useGitHubStore } from '@/stores/github';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'AppMinimalNavigationAccount',
  setup() {
    const { getAccount } = storeToRefs(useGitHubStore());
    const githubAccount = getAccount;

    const githubProfile = computed(() => {
      return {
        url: `https://github.com/${githubAccount.value?.name}`,
        label: `github.com/${githubAccount.value?.name}`,
      };
    });

    return {
      githubAccount,
      githubProfile,
    };
  },
});
