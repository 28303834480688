import { defineStore } from 'pinia';
import { AuthState } from '@/model/store';
import jsonwebtoken from 'jsonwebtoken';
import { EnvVars } from '@/utils/env-vars';
import { useAccountInfoStore } from '@/stores/account-info';
import i18n from '@/plugins/i18n';
import AuthService from '@/services/auth-service';
import { PINIA_PERSIST_KEY } from '@/utils/const';

const storeId = 'auth';

export const useAuthStore = defineStore(storeId, {
  state: (): AuthState => ({
    accessToken: null,
    accessTokenExp: 0,
    idToken: null,
    actor: undefined,
  }),
  getters: {
    loggedIn(state) {
      // Return a function so that this getter result will not be cached
      return () => state.accessToken && Date.now() < state.accessTokenExp;
    },
    customerData() {
      // @ts-ignore
      return !!this.idToken && jsonwebtoken.decode(this.idToken);
    },
    market() {
      // @ts-ignore
      return !!this.idToken && jsonwebtoken.decode(this.idToken).market;
    },
    firstName(): string {
      if (this.idToken) {
        // @ts-ignore
        return jsonwebtoken.decode(this.idToken).given_name;
      }
      return '';
    },
  },
  actions: {
    goToLogin(prompt: 'none' | 'login' | 'elogin' | null) {
      AuthService.goToLogin(prompt);
    },
    goToCentralLogout() {
      const market = localStorage.getItem('IONOS_SPACE_MARKET') || this.market;
      location.href = <string>EnvVars.getCentralLogoutUrl(market);
    },
    login({ accessToken, accessTokenExp, idToken, actor }: AuthState) {
      this.accessToken = accessToken;
      this.accessTokenExp = accessTokenExp;
      this.idToken = idToken;
      this.actor = actor;
      i18n.locale = this.market;
    },
    resetTokens() {
      this.accessToken = null;
      this.accessTokenExp = 0;
      this.idToken = null;
      this.actor = undefined;
    },
    logout(tokenInvalid?: boolean) {
      const accountInfoStore = useAccountInfoStore();

      if (!tokenInvalid) {
        AuthService.revokeAccessToken(<string>this.accessToken);
      }
      accountInfoStore.$reset();
      this.resetTokens();
      this.goToCentralLogout();
    },
    handleSessionTimeout() {
      this.logout(true);
    },
  },
  persist: {
    key: PINIA_PERSIST_KEY + '_' + storeId,
  },
});
