






























import { defineComponent } from '@vue/composition-api';
import i18n from '@/plugins/i18n';

export default defineComponent({
  setup(props, { root }) {
    const router = root.$router;

    const reload = () => {
      return router.push({ name: 'dashboard' });
    };

    const overview = () => {
      window.location.href = i18n.t('application.controlPanelUrl').toString();
    };

    return {
      reload,
      overview,
    };
  },
});
