export const DARK_MODE = 'dark';

export const SETUP_REPOSITORY = 'setup';
export const SETUP_FRAMEWORK = 'setup-select-framework';
export const SETUP_BUILD = 'setup-configure-build';
export const SETUP_DEPLOYMENT = 'setup-deployment';
export const SETUP_RUNTIME = 'setup-set-runtime';
export const SETUP_SUMMARY = 'setup-summary';
export const DASHBOARD = 'dashboard';
export const SIGN_UP = 'sign-up';
export const PROJECT_DETAIL = 'detail';
export const PROJECT_FILES = 'project-files';

export const routeNames = {
  SETUP_REPOSITORY,
  SETUP_FRAMEWORK,
  SETUP_BUILD,
  SETUP_DEPLOYMENT,
  SETUP_RUNTIME,
  SETUP_SUMMARY,
  DASHBOARD,
  SIGN_UP,
  PROJECT_DETAIL,
  PROJECT_FILES,
};

export const DB_USERNAME = 'IONOS_DB_USERNAME';
export const DB_PASSWORD = 'IONOS_DB_PASSWORD';
export const DB_HOST = 'IONOS_DB_HOST';
export const DB_NAME = 'IONOS_DB_NAME';

export const databaseSecrets = {
  DB_USERNAME,
  DB_PASSWORD,
  DB_HOST,
  DB_NAME,
};

export const IONOS_MAIL_HOST = 'IONOS_MAIL_HOST';
export const IONOS_MAIL_PORT = 'IONOS_MAIL_PORT';
export const IONOS_MAIL_USERNAME = 'IONOS_MAIL_USERNAME';
export const IONOS_MAIL_PASSWORD = 'IONOS_MAIL_PASSWORD';
export const IONOS_MAIL_ENCRYPTION = 'IONOS_MAIL_ENCRYPTION';
export const IONOS_MAIL_FROM_ADDRESS = 'IONOS_MAIL_FROM_ADDRESS';

export const mailAccountSecrets = {
  IONOS_MAIL_HOST,
  IONOS_MAIL_PORT,
  IONOS_MAIL_USERNAME,
  IONOS_MAIL_PASSWORD,
  IONOS_MAIL_ENCRYPTION,
  IONOS_MAIL_FROM_ADDRESS,
};

export const githubHttpsUrlRegex =
  /^(?:https?:\/\/)?github\.com\/(?<owner>[^/]+)\/(?<repo>[^/]+)(\/tree\/(?<ref>.+)|\.git)?$/;
export const githubSshUrlRegex = /^(?:ssh:\/\/)?git@github\.com:(?<owner>[^/]+)\/(?<repo>.+)\.git$/;

export const FRAMEWORK_CUSTOM_ID = 'custom';

export const PINIA_PERSIST_KEY = 'ionosspaceStore';

export const ACCOUNT_ME = 'me';
