














import router from '@/router/router';
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'AppExitSetupDialog',
  setup() {
    const exit = () => {
      return router.push({ name: 'dashboard' });
    };

    const dialog = ref(null as any);

    const open = () => {
      dialog.value && dialog.value.open();
    };

    const close = () => {
      dialog.value && dialog.value.close();
    };

    return {
      dialog,
      exit,
      open,
      close,
    };
  },
});
