import { branchApiClient, deploymentApiClient, projectApiClient } from '@/plugins/axios';
import { ACCOUNT_ME } from '@/utils/const';
import {
  branchToStoreBranch,
  deploymentToStoreDeployment,
  overviewBranchToStoreBranch,
  overviewDeploymentToStoreDeployment,
  overviewProjectToStoreProject,
  projectToStoreProject,
} from '@/model/storeApiConverter';
import { Branch, Deployment, Project } from '@/model/store';
import { SortField as ApiSortField } from 'ionos-space-api-v4';

export default class ProjectsApiService {
  static async loadListOfProjects(sort?: ApiSortField[]): Promise<Project[] | undefined> {
    const projects = await projectApiClient
      .getProjects(ACCOUNT_ME, {
        pageSize: 100,
        sort,
      })
      .then(({ data }) => {
        return data.values.map((project) => overviewProjectToStoreProject(project));
      })
      .catch((e) => console.log(e));

    return projects ?? undefined;
  }

  static async loadProject(projectId: string): Promise<Project | undefined> {
    const project = await projectApiClient
      .getProject(ACCOUNT_ME, projectId)
      .then(({ data }) => projectToStoreProject(data))
      .catch((e) => console.log(e));

    return project ?? undefined;
  }

  static async loadListOfBranches(projectId: string): Promise<Branch[] | undefined> {
    const branches = await branchApiClient
      .getBranches(ACCOUNT_ME, projectId, {
        pageSize: 100,
      })
      .then(({ data }) => {
        return data.values.map((branch) => overviewBranchToStoreBranch(projectId, branch));
      })
      .catch((error) => console.log(error));

    return branches ?? undefined;
  }

  static async loadBranch(projectId: string, branchId: string): Promise<Branch | undefined> {
    const branch = await branchApiClient
      .getBranch(ACCOUNT_ME, projectId, branchId)
      .then(({ data }) => branchToStoreBranch(projectId, data))
      .catch((e) => console.log(e));

    return branch ?? undefined;
  }

  static async loadListOfDeployments(projectId: string, branchId: string): Promise<Deployment[] | undefined> {
    const deployments = await deploymentApiClient
      .getDeployments(ACCOUNT_ME, projectId, branchId, {
        pageSize: 100,
      })
      .then(({ data }) => {
        return data.values.map((deployment) => overviewDeploymentToStoreDeployment(projectId, branchId, deployment));
      })
      .catch((e) => console.log(e));

    return deployments ?? undefined;
  }

  static async loadDeployment(
    projectId: string,
    branchId: string,
    deploymentId: string
  ): Promise<Deployment | undefined> {
    const deployment = await deploymentApiClient
      .getDeployment(ACCOUNT_ME, projectId, branchId, deploymentId)
      .then(({ data }) => deploymentToStoreDeployment(projectId, branchId, data))
      .catch((e) => console.log(e));

    return deployment ?? undefined;
  }
}
