















import { computed, defineComponent } from '@vue/composition-api';
import { NotificationType, useNotificationStore } from '@/stores/notifications';

export default defineComponent({
  name: 'AppNotificationsSnackbar',
  setup() {
    const notificationStore = useNotificationStore();

    const notification = computed(() => notificationStore.notification);

    const hasNotification = computed<boolean>(() => !!notification.value);
    const color = computed<string>(() => {
      switch (notification.value?.type) {
        case NotificationType.SUCCESS:
          return 'success';
        case NotificationType.ERROR:
          return 'error';
        default:
          return '';
      }
    });

    const icon = computed<string | null>(() => {
      switch (notification.value?.type) {
        case NotificationType.ERROR:
          return 'mdi-alert';
        default:
          return null;
      }
    });

    return {
      notification,
      hasNotification,
      color,
      icon,
    };
  },
});
