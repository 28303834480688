import { extend } from 'vee-validate';
import i18n from '@/plugins/i18n';

export type Schedule = { value: string } | string | null;

export function normalizeSchedule(schedule: Schedule): string {
  return typeof schedule === 'string' ? schedule : schedule?.value || '';
}

extend('cronjob', {
  validate: async (schedule: Schedule) => {
    console.log('validate', schedule, typeof schedule);
    const normalizedSchedule = normalizeSchedule(schedule);
    const regEx = RegExp(
      /(@(annually|yearly|monthly|weekly|daily|hourly|reboot))|(@every (\d+(ns|us|µs|ms|s|m|h))+)|((((\d+,)+\d+|(\d+(\/|-)\d+)|\d+|\*) ?){5,7})/
    );

    try {
      return regEx.test(normalizedSchedule);
    } catch (err: any) {
      return false;
    }
  },
  message: i18n.t('setup.forms.errors.cron').toString(),
});
