import Vue from 'vue';
import { ValidationProvider, extend, ValidationObserver, setInteractionMode, configure } from 'vee-validate';
import { required, regex, max, is } from 'vee-validate/dist/rules';
import i18n from '../i18n';
import './unique-repository-name';
import './existing-github-repository';
import './cronjob';

configure({
  defaultMessage: (field, values) => {
    if (!values) {
      values = {};
    }
    values._field_ = field;

    return <string>i18n.t(`validation.${values._rule_}`, values);
  },
});

extend('required', required);
extend('regex', regex);
extend('max', max);
extend('is', is);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

setInteractionMode('eager');
