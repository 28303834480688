import { UserSessionState } from '@/model/store';
import { defineStore } from 'pinia';
import { userSessionApiClient } from '@/plugins/axios';
import { useAuthStore } from '@/stores/auth';
import { DARK_MODE } from '@/utils/const';

export const useUserSessionStore = defineStore('user-session', {
  state: (): UserSessionState => ({
    frontendToken: undefined,
    darkMode: false,
  }),
  actions: {
    async loadToken() {
      const authStore = useAuthStore();
      if (authStore.loggedIn()) {
        const session = await userSessionApiClient.createUserSession().then(({ data }) => data);
        if (session) {
          this.frontendToken = session.frontendToken;
        }
      }
    },
    async loadTheme() {
      if (localStorage.getItem(DARK_MODE) !== null) {
        this.darkMode = localStorage.getItem(DARK_MODE) === 'true';
      } else if (window && window.matchMedia) {
        this.darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      }
    },
  },
});
