import { Account, DeploymentPatch, Patch, ProjectPatch, RestResponse, Deployment } from 'ionos-space-api-v4';
import { accountApiClient, deploymentApiClient, projectApiClient } from '@/plugins/axios';
import { ACCOUNT_ME } from '@/utils/const';
import { AccountPatch, Project } from '@/model/store';
import { useProjectsStore } from '@/stores/projects';
import { deploymentToStoreDeployment, projectToStoreProject } from '@/model/storeApiConverter';

export enum PatchOperation {
  replace = 'REPLACE',
  delete = 'DELETE',
}

function patch<T, P extends { [_ in K]: Patch<T> }, K extends keyof P>(
  key: K,
  payload: T,
  operation = PatchOperation.replace
): P {
  return {
    [key]: {
      operation,
      payload,
    },
  } as P;
}

//// Account

export function accountWelcomeShownPatch(payload: string): AccountPatch {
  return patch('welcomeShownPatch', payload);
}

export function accountFeedbackTileShownPatch(payload: string): AccountPatch {
  return patch('feedbackTileShownPatch', payload);
}

//// Project

export function projectNamePatch(payload: string): ProjectPatch {
  return patch('projectNamePatch', payload);
}

export function projectTypePatch(payload: string): ProjectPatch {
  return patch('projectTypePatch', payload);
}

export function automaticDeploymentEnabledPatch(payload: boolean): ProjectPatch {
  return patch('automaticDeploymentEnabledPatch', payload);
}

export function defaultPhpVersionPatch(payload: string): ProjectPatch {
  return patch('defaultPhpVersionPatch', payload);
}

export function defaultDomainRootPathPatch(payload: string): ProjectPatch {
  return patch('defaultDomainRootPathPatch', payload);
}

export function visitorStatisticsEnabledPatch(payload: boolean): ProjectPatch {
  return patch('visitorStatisticsEnabledPatch', payload);
}

//Deployment
export function deploymentNamePatch(payload: string): DeploymentPatch {
  return patch('namePatch', payload);
}

export function deploymentPhpVersionPatch(payload: string): DeploymentPatch {
  return patch('phpVersionPatch', payload);
}

export function deploymentRootPathPatch(payload: string): DeploymentPatch {
  return patch('deploymentRootPathPatch', payload);
}

//// Api

export function patchAccount(accountPatch: AccountPatch): RestResponse<Account> {
  // @ts-ignore
  return accountApiClient.patchAccount(ACCOUNT_ME, accountPatch);
}

export function patchProject(projectId: string, projectPatch: ProjectPatch): Promise<Project> {
  return projectApiClient.patchProject(ACCOUNT_ME, projectId, projectPatch).then(async ({ data }) => {
    const projectsStore = useProjectsStore();
    const storeProject = projectToStoreProject(data);
    await projectsStore.replaceProject(data.id, storeProject);
    return storeProject;
  });
}

export function patchDeployment(
  projectId: string,
  branchId: string,
  deploymentId: string,
  deploymentPatch: DeploymentPatch
): Promise<Deployment> {
  return deploymentApiClient
    .patchDeployment(ACCOUNT_ME, projectId, branchId, deploymentId, deploymentPatch)
    .then(async ({ data }) => {
      const projectsStore = useProjectsStore();
      const storeDeployment = deploymentToStoreDeployment(projectId, branchId, data);
      await projectsStore.replaceDeployment(projectId, branchId, data.id, storeDeployment);
      return storeDeployment;
    });
}
