
























import { defineComponent, computed } from '@vue/composition-api';
import AppContactSupportDialog from '@/components/shared/contactSupport/ContactSupportDialog.vue';
import AppMinimalNavigationAccount from '@/components/shared/navigation/minimalNavigation/MinimalNavigationAccount.vue';
import { useI18n } from 'vue-i18n-bridge';
import { storeToRefs } from 'pinia';
import { useProjectsStore } from '@/stores/projects';
import { useAuthStore } from '@/stores/auth';
import { useUserSessionStore } from '@/stores/user-session';
import { User } from 'ionos-vuetify/src/models';
import { DASHBOARD, PROJECT_DETAIL, PROJECT_FILES, SIGN_UP } from '@/utils/const';

export default defineComponent({
  name: 'AppMinimalNavigation',
  components: { AppContactSupportDialog, AppMinimalNavigationAccount },
  setup(props, { root, refs }) {
    const { t } = useI18n();

    const { selectedProject } = storeToRefs(useProjectsStore());
    const { loggedIn, customerData, actor } = storeToRefs(useAuthStore());
    const { frontendToken, darkMode } = storeToRefs(useUserSessionStore());

    const logoutUrl = computed<string>(() => {
      return `${window.location.origin}/logout`;
    });
    const user = computed<User>(() => {
      return {
        name: customerData.value?.name,
        organization: customerData.value?.organization,
        actor: actor?.value,
      };
    });
    const routeName = computed<string>(() => {
      switch (root.$route.name) {
        case DASHBOARD:
        case SIGN_UP:
          return '';
        case PROJECT_DETAIL:
        case PROJECT_FILES:
          return selectedProject.value?.name;
        default:
          return root.$route.name as string;
      }
    });

    return {
      user,
      loggedIn,
      logoutUrl,
      frontendToken,
      darkMode,
      routeName,
      helpMenuItems: [
        {
          label: t('navigation.helpMenuItems.provideFeedback.title'),
          href: t('navigation.helpMenuItems.provideFeedback.url'),
          target: '_blank',
        },
        {
          label: t('navigation.helpMenuItems.requestFeature.title'),
          href: t('navigation.helpMenuItems.requestFeature.url'),
          target: '_blank',
        },
        {
          label: t('navigation.helpMenuItems.reportBug.title'),
          callback: () => {
            (refs.contactSupportDialog as any).open();
          },
        },
        {
          label: t('navigation.helpMenuItems.documentation.title'),
          href: t('navigation.helpMenuItems.documentation.url'),
          target: '_blank',
        },
      ],
    };
  },
});
