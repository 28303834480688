















import { defineComponent, ref, computed, provide } from '@vue/composition-api';
import AppTariffUpgradeDialog from '@/components/shared/tariffUpgrade/TariffUpgradeDialog.vue';
import AppNavigation from '@/components/shared/navigation/Navigation.vue';
import AppFooter from '@/components/shared/footer/Footer.vue';
import _ from 'lodash';
import { DASHBOARD } from '@/utils/const';
import { useAuthStore } from '@/stores/auth';
import { mapBrowserLanguageToMarket } from '@/utils';

export default defineComponent({
  name: 'LoggedInLayout',
  components: { AppTariffUpgradeDialog, AppNavigation, AppFooter },
  props: {},
  setup(props, { root }) {
    const tariffUpgradeDialogRef = ref();

    const isDetails = computed<boolean>(() => {
      return _.some(root.$route.matched, 'meta.details');
    });
    const isSetup = computed<boolean>(() => {
      return _.some(root.$route.matched, 'meta.setup');
    });
    const isDashboard = computed<boolean>(() => {
      return root.$route.name === DASHBOARD;
    });
    const market = computed<string>(() => {
      const auth = useAuthStore();
      return auth.market ? auth.market : mapBrowserLanguageToMarket();
    });

    provide('openTariffUpgradeDialog', (args) => {
      tariffUpgradeDialogRef.value?.open(args);
    });

    return {
      isDetails,
      isSetup,
      isDashboard,
      market,
      tariffUpgradeDialogRef,
    };
  },
});
