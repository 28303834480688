import { extend } from 'vee-validate';
import { githubApiClient } from '@/plugins/axios';
import i18n from '@/plugins/i18n';
import { ACCOUNT_ME } from '@/utils/const';
import { useGitHubStore } from '@/stores/github';

extend('uniqueRepositoryName', {
  validate: async (value, args) => {
    if (args && Array.isArray(args) && args[0]) {
      const gitHubStore = useGitHubStore();
      if (!gitHubStore.internalInstallationId) return false;
      try {
        await githubApiClient.existsGithubRepository(ACCOUNT_ME, gitHubStore.internalInstallationId, args[0], value);
        return false;
      } catch (err: any) {
        if (err.response.status === 404) {
          return true;
        }
      }
    }

    return false;
  },
  message: i18n.t('setup.forms.errors.githubRepoAlreadyPresent').toString(),
});
