










import { defineComponent, computed } from '@vue/composition-api';
import AppNotificationsSnackbar from '@/components/shared/NotificationsSnackbar.vue';
import MinimalNavigation from '@/components/shared/navigation/minimalNavigation/MinimalNavigation.vue';
import { mapBrowserLanguageToMarket } from './utils';
import { useAuthStore } from './stores/auth';
import _ from 'lodash';
import i18n from '@/plugins/i18n';
import { useI18n } from 'vue-i18n-bridge';
import { useUserSessionStore } from './stores/user-session';

export default defineComponent({
  name: 'App',
  components: { AppNotificationsSnackbar, MinimalNavigation },
  props: {},
  emits: [],
  setup(props, { root }) {
    const { t } = useI18n();
    const authStore = useAuthStore();
    const sessionStore = useUserSessionStore();

    const setBackground = computed<string>(() => {
      return _.some(root.$route.matched, 'meta.details') ? 'app-details' : 'app';
    });

    document.title = t('application.name').toString();
    i18n.locale = authStore.market ? (authStore.market as string) : mapBrowserLanguageToMarket();

    sessionStore.loadTheme();

    return {
      minimalNavigationHeight: 32,
      setBackground,
    };
  },
});
